//===========================================
//  media query
//===========================================
$breakpoint-1: 768;
$breakpoint-2: 768;
//$breakpoint-2: 1024;

$breakpoints: (
  'sm': 'screen and (max-width: #{($breakpoint-1 + px)})',
  'md': 'screen and (min-width: #{($breakpoint-1 + 1 + px)}) and (max-width: #{($breakpoint-2 + px)})',
  'lg': 'screen and (min-width: #{($breakpoint-2 + 1 + px)}), print',
) !default;

@mixin media-query($bp) {
  @if map-has-key($breakpoints, $bp) {
    @media #{inspect(map-get($breakpoints, $bp))} {
      @content;
    }
  }
}
