/*===========================================
    c-button
===========================================*/

$tooltip-border-width-bottom: 0.6em;
$tooltip-border-width-oblique: 0.3em;
$tooltip-border-radius: 0;

//-------------------------------------------

[class*="c-tooltip"] {
  @include transition;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0.2em 0.4em;
  background-color: $color-text;
  border: solid $color-text;
  border-width: $button-border-width;
  border-radius: $button-border-radius;
  color: $color-white;
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;

  &:before {
    content: '';
    position: absolute;
    // top: 100%;
    // left: 50%;
    display: block;
    transform: translateX(- $tooltip-border-width-bottom / 2);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $tooltip-border-width-bottom $tooltip-border-width-oblique 0 $tooltip-border-width-oblique;
    border-color: $color-text transparent transparent transparent;
  }


  //-----------------------------------------
  //  color
  //-----------------------------------------
  &[class*="-black"] {
    background-color: $color-black;
    color: $color-white;
  }

  &[class*="-white"] {
    background-color: $color-white;
    color: $color-text;
  }

  &[class*="-primary"] {
    background-color: $color-primary;
    color: $color-white;
  }

  &[class*="-secondary"] {
    background-color: $color-secondary;
    color: $color-white;
  }


  //-----------------------------------------
  //  size
  //-----------------------------------------
  &[class*="-xl"] {
    font-size: $font-size-xl;
  }

  &[class*="-lg"] {
    font-size: $font-size-lg;
  }

  &[class*="-md"] {
    font-size: $font-size-md;
  }

  &[class*="-sm"] {
    font-size: $font-size-sm;
  }

  &[class*="-xs"] {
    font-size: $font-size-xs;
  }


  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-top"] {

    &:before {
      bottom: 100%;
      left: 50%;
      transform: translateX(- $tooltip-border-width-bottom / 2);
      border-width: 0 $tooltip-border-width-oblique $tooltip-border-width-bottom $tooltip-border-width-oblique;
      border-color: transparent transparent $color-text transparent;
    }
  }

  &[class*="-bottom"] {

    &:before {
      top: 100%;
      left: 50%;
      transform: translateX(- $tooltip-border-width-bottom / 2);
      border-width: $tooltip-border-width-bottom $tooltip-border-width-oblique 0 $tooltip-border-width-oblique;
      border-color: $color-text transparent transparent transparent;
    }
  }

  &[class*="-left"] {

    &:before {
      top: 50%;
      right: 100%;
      transform: translateY(- $tooltip-border-width-bottom / 2);
      border-width: $tooltip-border-width-oblique $tooltip-border-width-bottom $tooltip-border-width-oblique 0;
      border-color: transparent $color-text transparent transparent;
    }
  }

  &[class*="-right"] {

    &:before {
      top: 50%;
      left: 100%;
      transform: translateY(- $tooltip-border-width-bottom / 2);
      border-width: $tooltip-border-width-oblique 0 $tooltip-border-width-oblique $tooltip-border-width-bottom;
      border-color: transparent transparent transparent $color-text;
    }
  }
}
