/*===========================================
    c-table
===========================================*/

$table-border-type:  solid;
$table-border-width: 1px;
$table-border-color: $color-border;
$table-th-color:     lighten($color-border, 5%);;
$table-stripe-color: lighten($color-border, 8%);

//-------------------------------------------

[class*="c-table"] {
  width: 100%;
  overflow-x: scroll;
  @include media-query(sm) {
    display: block;
  }

  thead {
  }

  tbody {
  }

  tr {
  }

  th, td {
    padding: 0.8em 1em;
    line-height: 1.4;
  }

  th {
  }

  td {
  }

  //- -------------------------------------------
  //-    type
  //- -------------------------------------------
  &[class*="-box"] {

    th, td {
      border: $table-border-type $table-border-width $table-border-color;
    }

    th {
      background-color: $table-th-color;
    }
  }


  &[class*="-border"] {

    tbody {
      border-top: $table-border-type $table-border-width $table-border-color;
      border-bottom: $table-border-type $table-border-width $table-border-color;

      th {
        color: $color-text;
        font-size: 1em;
        font-weight: bold;
      }
    }

    tr {
      border-top: $table-border-type $table-border-width $table-border-color;

      &:first-child {
        border: none;
      }
    }

    th {
      color: lighten($color-text, 60%);
      font-size: 0.8em;
    }

    th, td {
      vertical-align: middle;
    }
  }


  //- -------------------------------------------
  //-    option
  //- -------------------------------------------
  &[class*="-hover"] {

    tbody {

      tr {
        @include transition;

        &:hover {
          background-color: rgba($color-primary, 0.1);
        }
      }
    }
  }

  &[class*="-stripe"] {

    thead {
      // border-top: $table-border-type $table-border-width $table-border-color;
      background-color: $table-th-color;
    }

    tbody {

      tr {

        &:nth-child(2n) {
          background-color: $table-stripe-color;
        }
      }
    }
  }
}
