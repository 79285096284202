//===========================================
//  function
//===========================================

//-------------------------------------------
//  hover
//-------------------------------------------
$hover-opacity: 0.6;


//-------------------------------------------
//  form
//-------------------------------------------
$form-input-padding: 0.6em 1em;
$form-input-margin:  1em;

$form-input-fontsize: $font-size-md;

$form-input-border-type:  solid;
$form-input-border-width: 1px;
$form-input-border-color: $color-border;

$form-input-background-color: $color-white;

$form-focus-color:       $color-primary;
$form-disabled-color:    lighten($color-border, 5%);
$form-placeholder-color: lighten($color-text, 80%);
