/*===========================================
    c-breadcrumb
===========================================*/

//-------------------------------------------

[class*="c-breadcrumb"] {
  display: flex;
  flex-wrap: wrap;
  font-size: $font-size-sm;

  li {
    white-space: nowrap;

    &:after {
      content: '>';
      padding: 0 0.6em;
    }

    &:last-child {

      &:after {
        content: none;
      }
    }

    a {
      color: $color-text;
      white-space: inherit;
    }
  }

  //-----------------------------------------
  // type
  //-----------------------------------------
  &[class*="-scroll"] {
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 1em;
  }
}
