/*===========================================
    l-footer
===========================================*/
.l-footer {
  width: 100%;
  background-color: $color-background;

  .footer__inner {
    @include media-query(lg) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 56px 48px;
    }
    @include media-query(sm) {
      padding: 48px 32px;
    }
  }

  .footer__logo {
    @include media-query(lg) {
      width: 184px;
      margin-bottom: 32px;
    }
    @include media-query(sm) {
      width: 40%;
      margin-bottom: 32px;
    }
  }

  .footer__profile {
    font-size: $font-size-xs;
    @include media-query(lg) {
      width: 70%;
    }
    @include media-query(sm) {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  .footer__tel {
    margin-bottom: 32px;

    p {
      display: inline-block;
    }
  }

  .footer__link {

  }

  .footer__copyright {
    font-size: $font-size-xs;
    text-align: right;
    @include media-query(lg) {
      width: 30%;
    }
    @include media-query(sm) {
      width: 100%;
    }
  }
}
