/*===========================================
    c-navigation
===========================================*/

//-------------------------------------------

[class*="c-navigation"] {
  padding-bottom: 8px;
  overflow-x: scroll;
  overflow-y: hidden;

  .navigation__list {
    display: flex;

    li {
      margin-left: 4em;

      &:first-child {
        margin-left: 0;
      }

      a {
        position: relative;
        display: block;
        height: calc(1em + 2em);
        padding: 1em 0;
        // color: lighten($color-text, 60%);
        text-decoration: none;

        // &.is-active,
        // &:hover {
        //   color: $color-text;
        // }
      }
    }
  }

  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-line"] {

    .navigation__list {

      li {
        border-bottom: solid 1px $color-border;

        a {

          &:before {
            @include transition;
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
          }

          &.is-active {

            &:before {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $color-primary;
            }
          }
        }
      }
    }
  }

  &[class*="-box"] {
    padding-bottom: 0;
    border: solid 1px $color-border;

    .navigation__list {
      display: inline-flex;

      li {
        border-left: solid 1px $color-border;

        a {
          display: flex;
          justify-content: center;
          align-items: center;

          &.is-active {
            background-color: $color-primary;
            color: $color-white;
          }
        }
      }
    }
  }
}
