/*===========================================
    js-filter
===========================================*/
.js-filter {
}

.js-filter__navigation {
}

.js-filter__button {
}

.js-filter__wrapper {
}

.js-filter__content {
  @include transition;
  display: none;
  transform: scale(0);
  opacity: 0;

  &.is-active {
    @include animation;
    animation-name: fade-in;
    display: block;
    transform: scale(1);
    opacity: 1;
  }
}
