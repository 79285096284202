/*===========================================
    c-input
===========================================*/

//-------------------------------------------

[class*="c-input"] {
  position: relative;
  display: inline-block;

  input[type="text"],
  input[type="password"],
  input[type="tel"],
  input[type="url"],
  input[type="email"],
  input[type="date"],
  input[type="time"],
  input[type="search"],
  textarea,
  select {
    @include transition;
    position: relative;
    display: block;
    width: 100%;
    height: calc(1em + 2em);
    padding: $form-input-padding;
    border: $form-input-border-type $form-input-border-width $form-input-border-color;
    border-radius: 0;
    background-color: $form-input-background-color;
    font-size: $form-input-fontsize;

    &:focus {
      z-index: 1;
      border: $form-input-border-type $form-input-border-width $form-focus-color;
      box-shadow: 0 0 0.5em rgba($form-focus-color, 0.4);
    }

    &:disabled {
      background-color: $form-disabled-color;
      color: $form-placeholder-color;
    }

    &::placeholder {
      color: $form-placeholder-color;
    }
  }

  textarea {
    min-height: 5em;
  }

  .input__select {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 1.5em;
      z-index: 2;
      transform: translateY(-50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6.9px 4px 0 4px;
      border-color: $color-text transparent transparent transparent;
    }
  }


  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-line"] {

    .input__effect {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 2px;
      background-color: $form-input-border-color;

      &:before {
        @include transition;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: $form-focus-color;
      }
    }

    input[type="text"],
    input[type="password"],
    input[type="tel"],
    input[type="url"],
    input[type="email"],
    input[type="date"],
    input[type="time"],
    input[type="search"],
    textarea,
    select {
      @include transition;
      display: block;
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      border: none;

      &:focus {
        border-color: $form-input-border-color;
        box-shadow: none;

        & + .input__effect {
          &:before {
            width: 100%;
          }
        }
      }

      &:disabled {
        .input__effect {
          background-color: $form-disabled-color;
        }
      }

      &::placeholder {
        color: $form-placeholder-color;
      }
    }
  }


  //-----------------------------------------
  //  option
  //-----------------------------------------
  &[class*="-icon"] {

    input[type="text"],
    input[type="password"],
    input[type="tel"],
    input[type="url"],
    input[type="email"],
    input[type="date"],
    input[type="time"],
    input[type="search"],
    textarea,
    select {
      padding-left: 3.5em;
    }

    .input__icon {
      position: absolute;
      top: 50%;
      left: 1em;
      z-index: 1;
      transform: translateY(-50%);
      width: 1.5em;
      height: 1.5em;
    }
  }
}
