/*===========================================
    c-window
===========================================*/

$window-padding-mag: 2;
$window-spacing-mag: 1;

//-------------------------------------------

[class*="c-window"] {
  position: relative;
  z-index: 1;
  display: block;
  background-color: $color-white;

  & > * {
    margin-top: $font-size-md * $heding-size-mag;

    &:first-child {
      margin-top: 0 !important;
    }
  }

  .window__caption {
    font-size: 2em;
  }

  .window__close {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    display: block;
    margin-top: 0 !important;
  }


  //-----------------------------------------
  //  color
  //-----------------------------------------
  &[class*="-black"] {
    background-color: $color-black;
  }

  &[class*="-white"] {
    background-color: $color-white;
  }

  &[class*="-primary"] {
    background-color: $color-primary;
  }

  &[class*="-secondary"] {
    background-color: $color-secondary;
  }


  //-----------------------------------------
  //  size
  //-----------------------------------------
  @mixin window-size($fontSize: $font-size-md) {
    padding: $fontSize * $window-padding-mag $fontSize * $window-padding-mag * 1.2;
    font-size: $fontSize;

    & > * {
      margin-top: $fontSize * $window-spacing-mag;
    }
  }

  &[class*="-xl"] {
    @include window-size($font-size-xl);
  }

  &[class*="-lg"] {
    @include window-size($font-size-lg);
  }

  &[class*="-md"] {
    @include window-size($font-size-md);
  }

  &[class*="-sm"] {
    @include window-size($font-size-sm);
  }

  &[class*="-xs"] {
    @include window-size($font-size-xs);
  }


  //-----------------------------------------
  //  option
  //-----------------------------------------
  &[class*="-shadow"] {
    box-shadow: 1em 1em 1em $color-shadow;
  }

}
