/*===========================================
    js-loading
===========================================*/
.js-loading {
  @include transition;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: $color-white;
  opacity: 1;

  &.is-active {
    opacity: 0;
    pointer-events: none;
  }

  .loading__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
}
