//===========================================
//  animation
//===========================================
@mixin animation($duration: 0.8s, $timing: ease, $delay: 0, $count: 1, $direction: nomal, $fill-mode: both, $play-state: running) {
  animation-duration: $duration;
  animation-delay: $delay;
  animation-timing-function: $timing;
  animation-iteration-count: $count;
  animation-direction:$direction;
  animation-fill-mode: $fill-mode;
  animation-play-state: $play-state;
}
