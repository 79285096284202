/*===========================================
    reset
===========================================*/
html {
  overflow-y: auto;
}

body {
  position: relative;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

html, body, header, nav, footer, main, aside, section, article, nav, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, caption, mark, dl, dt, dd, ol, ul, li, fieldset, label, legend, option, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 100%;
  font-style: normal;
  font-weight: normal;
  vertical-align: top;
  text-align: left;
}

dl, dt, dd, ol, ul, li {
  list-style: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  *border-collapse: collapse;
  border-spacing: 0;
}

th, td {
  border: none;
  text-align: left;
  vertical-align: top;
}

pre {
}

q, blockquote {
  quotes: none;
}

iframe {
  display: block;
  width: 100%;
  overflow: hidden;
}

img {
  border: none;
  vertical-align: bottom;
}

a {
  border: none;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  outline: none;
  cursor: pointer;
}

a:active, a:focus {
  outline: none;
}

sup {
  position: relative;
  bottom: 0.7em;
  font-size: 71.5%;
  vertical-align: baseline;
}

sub {
  position: relative;
  top: 0.1em;
  font-size: 71.5%;
  vertical-align: baseline;
}

abbr, acronym {
  border-bottom: dotted 1px #ccc;
  cursor: help;
}

hr {
  display: none;
}


//-----------------------------------------
//  form reset
//-----------------------------------------
button,
input,
select,
textarea {
  border: none;
  outline: none;
  appearance: none;
  font-size: inherit;
  letter-spacing: inherit;
}

input[type="checkbox"] {
  appearance: checkbox;
  cursor: pointer;
}

input[type="radio"] {
  appearance: radio;
  cursor: pointer;
}

input[type="number"] {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="search"] {
  -webkit-appearance: textfield;

  &:focus {
    outline-offset: -2px;
  }

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

input[type="send"],
input[type="reset"],
input[type="button"] {
  cursor: pointer;
}

input[type="range"] {
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
}

textarea {
  resize: none;
}

button, select {
  cursor: pointer;
}
