//===========================================
//  fx-link-fs
//===========================================

//-------------------------------------------
//  mouseOn
//-------------------------------------------
@keyframes link-fs {
  0% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
  20% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
  100% {
    transform-origin: left top;
    transform: scale(1, 1);
  }
}

//-------------------------------------------
//  実行
//-------------------------------------------

.fx-link-fs {

  &:hover {

    &:after {
      animation-name: link-fs;
    }
  }
}
