/*===========================================
    c-button
===========================================*/

$label-border-width: 1px;
$label-border-radius: 0;

//-------------------------------------------

[class*="c-label"] {
  @include transition;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0.2em 0.4em;
  background-color: $color-text;
  border: solid $color-text;
  border-width: $button-border-width;
  border-radius: $button-border-radius;
  color: $color-white;
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;


  //-----------------------------------------
  //  size
  //-----------------------------------------
  &[class*="-xl"] {
    font-size: $font-size-xl;
  }

  &[class*="-lg"] {
    font-size: $font-size-lg;
  }

  &[class*="-md"] {
    font-size: $font-size-md;
  }

  &[class*="-sm"] {
    font-size: $font-size-sm;
  }

  &[class*="-xs"] {
    font-size: $font-size-xs;
  }

  
  //-----------------------------------------
  //  color
  //-----------------------------------------
  &[class*="-black"] {
    background-color: $color-black;
    color: $color-white;
  }

  &[class*="-white"] {
    background-color: $color-white;
    color: $color-text;
  }

  &[class*="-primary"] {
    background-color: $color-primary;
    color: $color-white;
  }

  &[class*="-secondary"] {
    background-color: $color-secondary;
    color: $color-white;
  }


  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-badge"] {
    min-width: 2em;
    min-height: 2em;
    border-radius: 1em;
  }
}
