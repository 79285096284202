/*===========================================
    c-note
===========================================*/

//-------------------------------------------

[class*="c-note"] {
  padding-left: 1.4em;
  font-size: $font-size-sm;
  text-indent: -1.4em;

  // &:before {
  //   content: '※';
  // }


  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-number"] {
    padding-left: 2em;
    text-indent: -2em;

    &:before {
      content: none;
    }
  }
}
