/*===========================================
    u-grid
===========================================*/

$grid-gap-lg: 2.5rem;
$grid-gap-md: 1.5rem;
$grid-gap-sm: 0.5rem;

//-------------------------------------------

[class*="u-grid"] {
  display: flex !important;
  flex-wrap: wrap !important;
  width: 100% !important;

  & > * {

    &:first-child {
      margin-left: 0 !important;

      @include media-query(sm) {
        margin-top: 0 !important;
      }
    }
  }

  //-----------------------------------------
  //  列
  //-----------------------------------------
  //lg
  &[class*="-l1"] {

    & > * {
      @include media-query(lg) {
        width: 100% !important;
        margin-left: 0 !important;
      }

      &:nth-child(-n + 1) {
        @include media-query(lg) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-l2"] {

    & > * {
      @include media-query(lg) {
        width: calc(100% / 2) !important;
      }

      &:nth-child(2n-1) {
        @include media-query(lg) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 2) {
        @include media-query(lg) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-l3"] {

    & > * {
      @include media-query(lg) {
        width: calc(100% / 3) !important;
      }

      &:nth-child(3n-2) {
        @include media-query(lg) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 3) {
        @include media-query(lg) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-l4"] {

    & > * {
      @include media-query(lg) {
        width: calc(100% / 4) !important;
      }

      &:nth-child(4n-3) {
        @include media-query(lg) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 4) {
        @include media-query(lg) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-l5"] {

    & > * {
      @include media-query(lg) {
        width: calc(100% / 5) !important;
      }

      &:nth-child(5n-4) {
        @include media-query(lg) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 5){
        @include media-query(lg) {
          margin-top: 0 !important;
        }
      }
    }
  }

  //md
  &[class*="-m1"] {

    & > * {
      @include media-query(md) {
        width: 100% !important;
        margin-left: 0 !important;
      }

      &:nth-child(-n + 1) {
        @include media-query(md) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-m2"] {

    & > * {
      @include media-query(md) {
        width: calc(100% / 2) !important;
      }

      &:nth-child(2n-1) {
        @include media-query(md) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 2) {
        @include media-query(md) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-m3"] {

    & > * {
      @include media-query(md) {
        width: calc(100% / 3) !important;
      }

      &:nth-child(3n-2) {
        @include media-query(md) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 3), {
        @include media-query(md) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-m4"] {

    & > * {
      @include media-query(md) {
        width: calc(100% / 4) !important;
      }

      &:nth-child(4n-3) {
        @include media-query(md) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 4) {
        @include media-query(md) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-m5"] {

    & > * {
      @include media-query(md) {
        width: calc(100% / 5) !important;
      }

      &:nth-child(5n-4) {
        @include media-query(md) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 5) {
        @include media-query(md) {
          margin-top: 0 !important;
        }
      }
    }
  }

  //sm
  &[class*="-s1"] {

    & > * {
      @include media-query(sm) {
        width: 100% !important;
        margin-left: 0 !important;
      }

      &:nth-child(-n + 1) {
        @include media-query(sm) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-s2"] {

    & > * {
      @include media-query(sm) {
        width: calc(100% / 2) !important;
      }

      &:nth-child(2n-1) {
        @include media-query(sm) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 2) {
        @include media-query(sm) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-s3"] {

    & > * {
      @include media-query(sm) {
        width: calc(100% / 3) !important;
      }

      &:nth-child(3n-2) {
        @include media-query(sm) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 3), {
        @include media-query(sm) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-s4"] {

    & > * {
      @include media-query(sm) {
        width: calc(100% / 4) !important;
      }

      &:nth-child(4n-3) {
        @include media-query(sm) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 4){
        @include media-query(sm) {
          margin-top: 0 !important;
        }
      }
    }
  }

  &[class*="-s5"] {

    & > * {
      @include media-query(sm) {
        width: calc(100% / 5) !important;
      }

      &:nth-child(5n-4) {
        @include media-query(sm) {
          margin-left: 0 !important;
        }
      }

      &:nth-child(-n + 5) {
        @include media-query(sm) {
          margin-top: 0 !important;
        }
      }
    }
  }


  //-----------------------------------------
  //  空き
  //-----------------------------------------
  &[class*="-lg"] {

    & > * {
      margin-top: $grid-gap-lg;
      margin-left: $grid-gap-lg;
    }
    @include media-query(lg) {
      &[class*="-l5"] > *{
        width: calc((100% - #{$grid-gap-lg} * 4) / 5) !important;
      }
      &[class*="-l4"] > * {
        width: calc((100% - #{$grid-gap-lg} * 3) / 4) !important;
      }
      &[class*="-l3"] > * {
        width: calc((100% - #{$grid-gap-lg} * 2) / 3) !important;
      }
      &[class*="-l2"] > * {
        width: calc((100% - #{$grid-gap-lg} * 1) / 2) !important;
      }
    }
    @include media-query(md) {
      &[class*="-m5"] > * {
        width: calc((100% - #{$grid-gap-lg} * 4) / 5) !important;
      }
      &[class*="-m4"] > * {
        width: calc((100% - #{$grid-gap-lg} * 3) / 4) !important;
      }
      &[class*="-m3"] > * {
        width: calc((100% - #{$grid-gap-lg} * 2) / 3) !important;
      }
      &[class*="-m2"] > * {
        width: calc((100% - #{$grid-gap-lg} * 1) / 2) !important;
      }
    }
    @include media-query(sm) {
      &[class*="-s5"] > * {
        width: calc((100% - #{$grid-gap-lg} * 4) / 5) !important;
      }
      &[class*="-s4"] > * {
        width: calc((100% - #{$grid-gap-lg} * 3) / 4) !important;
      }
      &[class*="-s3"] > * {
        width: calc((100% - #{$grid-gap-lg} * 2) / 3) !important;
      }
      &[class*="-s2"] > * {
        width: calc((100% - #{$grid-gap-lg} * 1) / 2) !important;
      }
    }

    &[class*="-jfs"] > *,
    &[class*="-jfb"] > *,
    &[class*="-jct"] > * {
      width: auto !important;
      margin-top: 0 !important;
    }
  }

  &[class*="-md"] {

    & > * {
      margin-top: $grid-gap-md;
      margin-left: $grid-gap-md;
    }

    @include media-query(lg) {
      &[class*="-l5"] > *{
        width: calc((100% - #{$grid-gap-md} * 4) / 5) !important;
      }
      &[class*="-l4"] > * {
        width: calc((100% - #{$grid-gap-md} * 3) / 4) !important;
      }
      &[class*="-l3"] > * {
        width: calc((100% - #{$grid-gap-md} * 2) / 3) !important;
      }
      &[class*="-l2"] > * {
        width: calc((100% - #{$grid-gap-md} * 1) / 2) !important;
      }
    }
    @include media-query(md) {
      &[class*="-m5"] > * {
        width: calc((100% - #{$grid-gap-md} * 4) / 5) !important;
      }
      &[class*="-m4"] > * {
        width: calc((100% - #{$grid-gap-md} * 3) / 4) !important;
      }
      &[class*="-m3"] > * {
        width: calc((100% - #{$grid-gap-md} * 2) / 3) !important;
      }
      &[class*="-m2"] > * {
        width: calc((100% - #{$grid-gap-md} * 1) / 2) !important;
      }
    }
    @include media-query(sm) {
      &[class*="-s5"] > * {
        width: calc((100% - #{$grid-gap-md} * 4) / 5) !important;
      }
      &[class*="-s4"] > * {
        width: calc((100% - #{$grid-gap-md} * 3) / 4) !important;
      }
      &[class*="-s3"] > * {
        width: calc((100% - #{$grid-gap-md} * 2) / 3) !important;
      }
      &[class*="-s2"] > * {
        width: calc((100% - #{$grid-gap-md} * 1) / 2) !important;
      }
    }

    &[class*="-jfs"] > *,
    &[class*="-jfb"] > *,
    &[class*="-jct"] > * {
      width: auto !important;
      margin-top: 0 !important;
    }
  }

  &[class*="-sm"] {

    & > * {
      margin-top: $grid-gap-sm;
      margin-left: $grid-gap-sm;
    }

    @include media-query(lg) {
      &[class*="-l5"] > *{
        width: calc((100% - #{$grid-gap-sm} * 4) / 5) !important;
      }
      &[class*="-l4"] > * {
        width: calc((100% - #{$grid-gap-sm} * 3) / 4) !important;
      }
      &[class*="-l3"] > * {
        width: calc((100% - #{$grid-gap-sm} * 2) / 3) !important;
      }
      &[class*="-l2"] > * {
        width: calc((100% - #{$grid-gap-sm} * 1) / 2) !important;
      }
    }
    @include media-query(md) {
      &[class*="-m5"] > * {
        width: calc((100% - #{$grid-gap-sm} * 4) / 5) !important;
      }
      &[class*="-m4"] > * {
        width: calc((100% - #{$grid-gap-sm} * 3) / 4) !important;
      }
      &[class*="-m3"] > * {
        width: calc((100% - #{$grid-gap-sm} * 2) / 3) !important;
      }
      &[class*="-m2"] > * {
        width: calc((100% - #{$grid-gap-sm} * 1) / 2) !important;
      }
    }
    @include media-query(sm) {
      &[class*="-s5"] > * {
        width: calc((100% - #{$grid-gap-sm} * 4) / 5) !important;
      }
      &[class*="-s4"] > * {
        width: calc((100% - #{$grid-gap-sm} * 3) / 4) !important;
      }
      &[class*="-s3"] > * {
        width: calc((100% - #{$grid-gap-sm} * 2) / 3) !important;
      }
      &[class*="-s2"] > * {
        width: calc((100% - #{$grid-gap-sm} * 1) / 2) !important;
      }
    }

    &[class*="-jfs"] > *,
    &[class*="-jfb"] > *,
    &[class*="-jct"] > * {
      width: auto !important;
      margin-top: 0 !important;
    }
  }


  //-----------------------------------------
  // justify-content
  //-----------------------------------------
  &[class*="-jfs"] {
    justify-content: flex-start !important;

    & > * {
      width: auto !important;
    }
  }

  &[class*="-jfb"] {
    justify-content: flex-end !important;

    & > * {
      width: auto !important;
    }
  }

  &[class*="-jct"] {
    justify-content: center !important;

    & > * {
      width: auto !important;
    }
  }

  &[class*="-jsb"] {
    justify-content: space-between !important;

    & > * {
      width: auto !important;
    }
  }

  &[class*="-jsa"] {
    justify-content: space-around !important;

    & > * {
      width: auto !important;
    }
  }

  &[class*="-jse"] {
    justify-content: space-evenly !important;

    & > * {
      width: auto !important;
    }
  }


  //-----------------------------------------
  // justify-content
  //-----------------------------------------
  &[class*="-afs"] {
    align-items: flex-start !important;

    & > * {
      width: auto !important;
    }
  }

  &[class*="-afe"] {
    align-items: flex-end !important;

    & > * {
      width: auto !important;
    }
  }

  &[class*="-act"] {
    align-items: center !important;

    & > * {
      width: auto !important;
    }
  }

  &[class*="-abl"] {
    align-items: baseline !important;

    & > * {
      width: auto !important;
    }
  }

  &[class*="-asr"] {
    align-items: stretch !important;

    & > * {
      width: auto !important;
    }
  }


  //-----------------------------------------
  // nowrap
  //-----------------------------------------
  &[class*="-nowrap"] {
    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    padding-bottom: 1rem !important;

    & > * {
      width: auto !important;
      margin-top: 0 !important;

      &:first-child {
        margin-left: 0 !important;
      }
    }
  }


  //-----------------------------------------
  // direction:
  //-----------------------------------------


  &[class*="-drow"] {
    flex-direction: row !important;
  }

  &[class*="-dcol"] {
    flex-direction: column !important;
  }



  &[class*="-drrow"] {
    flex-direction: row-reverse !important;
  }

  &[class*="-drcol"] {
    flex-direction: column-reverse !important;
  }
}
