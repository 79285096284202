/*===========================================
    c-radio
===========================================*/

//-------------------------------------------

[class*="c-radio"] {
  position: relative;
  display: inline-block;
  padding-left: 1.8em;
  cursor: pointer;

  input {
    display: none;

    &:checked {

      & + .radio__icon {

        &:before {
          transform: scale(1);
        }

        &:after {
          opacity: 1;
        }
      }
    }

    &:disabled {

      & + .radio__icon {
        border: $form-input-border-type $form-input-border-width $form-input-border-color;
        background-color: $form-disabled-color;
      }
    }
  }

  .radio__icon {
    position: absolute;
    top: 0.2em;
    left: 0;
    display: block;
    width: 1.4em;
    height: 1.4em;
    background-color: $color-white;
    border-radius: 50%;
    border: $form-input-border-type $form-input-border-width $form-input-border-color;
    vertical-align: middle;

    &:before,
    &:after {
      @include transition;
      content: '';
      position: absolute;
      display: block;
    }

    &:before {
      top: - #{$form-input-border-width};
      left: - #{$form-input-border-width};
      transform: scale(0);
      width: 100%;
      height: 100%;
      background-color: $form-focus-color;
      border-radius: 50%;
      border: $form-input-border-type $form-input-border-width $form-focus-color;
    }

    &:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transform-origin: center;
      width: 0.5em;
      height: 0.5em;
      background-color: $color-white;
      border-radius: 50%;
      opacity: 0;
    }
  }


  //-----------------------------------------
  //  combination
  //-----------------------------------------
  &[class*="-button"] {
    padding: 0;
  }
}
