/*===========================================
    l-main
===========================================*/
.l-main {
  position: relative;
  overflow-x: hidden;
  width: 100vw;

  .main__reference {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    opacity: 0.2;

    img {
       width: 100%;
    }
  }
}
