/*===========================================
    c-button
===========================================*/

$button-border-width: 0;
$button-border-radius: 0;

//-------------------------------------------

[class*="c-button"] {
  @include transition;
  position: relative;
  display: inline-flex;
  justify-content: justify-content;
  align-items: center;
  width: auto;
  height: calc(1em + 2em);
  padding: 1em 2.5em;
  background-color: $color-text;
  border: solid $color-text;
  border-width: $button-border-width;
  border-radius: $button-border-radius;
  color: $color-white;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    opacity: $hover-opacity;
  }

  &.is-active,
  input:checked + & {
    background-color: $color-white;
    border: solid $color-text;
    border-width: $button-border-width;
    color: $color-text;
  }

  &.is-disabled,
  input:disabled + &  {
    opacity: $hover-opacity;
    pointer-events: none;
  }

  [class*="button__icon"] {
    width: 1.5em;
    height: 1.5em;

    &[class*='--before'] {
      margin: 0 1.5em 0 -1.5em;
    }

    &[class*='--after'] {
      margin: 0 -1.5em 0 1.5em;
    }

    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }

  input {
    display: none;
  }


  //-----------------------------------------
  //  size
  //-----------------------------------------
  &[class*="-xl"] {
    font-size: $font-size-xl;
  }

  &[class*="-lg"] {
    font-size: $font-size-lg;
  }

  &[class*="-md"] {
    font-size: $font-size-md;
  }

  &[class*="-sm"] {
    font-size: $font-size-sm;
  }

  &[class*="-xs"] {
    font-size: $font-size-xs;
  }


  //-----------------------------------------
  //  color
  //-----------------------------------------
  @mixin button-color($backgroundColor, $fontColor, $fontColorActive) {
    background-color: $backgroundColor;
    color: $fontColor;
    @content;

    &.is-active {
      background-color: transparent;
      border: solid $backgroundColor;
      border-width: $button-border-width;
      color: $fontColorActive;
    }
  }

  &[class*="-black"] {
    @include button-color($color-black, $color-white, $color-black)
  }

  &[class*="-white"] {
    @include button-color($color-white, $color-text, $color-white)
  }

  &[class*="-primary"] {
    @include button-color($color-primary, $color-white, $color-primary)
  }

  &[class*="-secondary"] {
    @include button-color($color-secondary, $color-white, $color-secondary)
  }


  //-----------------------------------------
  //  option
  //-----------------------------------------
  &[class*="-full"] {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
