/*===========================================
    c-pagenation
===========================================*/

//-------------------------------------------

[class*="c-pagenation"] {
  display: flex;
  align-items: center;

  li {
    margin-left: 2em;

    &:first-child {
      margin-left: 0;
    }

    a {
      display: block;
      color: lighten($color-text, 60%);
      line-height: 1;
      white-space: nowrap;
      vertical-align: middle;
      text-decoration: none;

      i {
        width: 1.4em;
        height: 1.4em;
      }

      &.is-active,
      &:hover {
        color: $color-text;
      }

      &.is-active {
        pointer-events: none;
      }
    }
  }

  //- -------------------------------------------
  //-    type
  //- -------------------------------------------
  &[class*="-between"] {
    width: 100%;
    justify-content: space-between;

    li {

      &.pagenation__prev {

        a {
          line-height: 1;

          i {
            margin-right: 0.5em;
          }
        }
      }

      &.pagenation__next {

        a {
          line-height: 1;

          i {
            margin-left: 0.5em;
          }
        }
      }
    }
  }
}
