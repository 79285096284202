/*===========================================
    c-link
===========================================*/

$button-border-width: 2px;
$button-border-radius: 0;

//-------------------------------------------

[class*="c-link"] {
  @include transition;
  color: $color-link;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    opacity: $hover-opacity;
  }

  &.is-active {
  }

  &.is-disabled {
    opacity: $hover-opacity;
    pointer-events: none;
  }


  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-icon"] {

    i, img {
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      vertical-align: middle;
    }
  }

  &[class*="-bkimg"] {

    &:before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      background-image: url('http://placehold.it/64x64');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &[class*="-fs"] {
    @include transition;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding: 0;
    color: $color-black;
    font-size: 0.8rem;
    font-family: $font-family-fs;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    i {
      width: 2.5rem;
      height: 2.5rem;
      margin-left: 1em;
    }

    &.is-active {

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1.5em;
        margin-top: 0.3em;
        background-color: $color-primary;
      }
    }

    &:hover {
      opacity: 1;

      &:after {
        @include animation(0.4s);
        animation-name: link-fs;
        content: '';
        display: block;
        width: 100%;
        height: 1.5em;
        margin-top: 0.3em;
        background-color: $color-primary;
      }
    }

    &[class*="-100"] {

      &:after {
        height: 8rem;
      }
    }
  }
}
