/*===========================================
    p-sample
===========================================*/
@import url('https://fonts.googleapis.com/css?family=Fira+Code:400,600&display=swap');
$font-famiry-sample: 'Fira Code', monospace;

#p-sample {

  main {
    position: relative;

    &.is-color-original {
      background-color: #f5f5f5;
      color: $color-black;

      a {
        color: $color-black;

        &:before {
          border: solid 1px $color-black;
        }

        &.is-active {

          &:before {
            background-color: $color-black;
            border: solid 1px $color-black;
          }
        }
      }
    }

    &.is-color-white {
      background-color: $color-white;
      color: $color-black;

      a {
        color: $color-black;

        &:before {
          border: solid 1px $color-black;
        }

        &.is-active {

          &:before {
            background-color: $color-black;
            border: solid 1px $color-black;
          }
        }
      }
    }

    &.is-color-black {
      background-color: $color-black;
      color: $color-white;

      a {
        color: $color-white;

        &:before {
          border: solid 1px $color-white;
        }

        &.is-active {

          &:before {
            background-color: $color-white;
            border: solid 1px $color-white;
          }
        }
      }
    }
  }

  .p-head {
    font-family: $font-famiry-sample;
    font-size: $font-size-xs;

    .head__index {
      position: fixed;
      top: 2rem;
      left: 2rem;
      width: 12rem;

      nav {
        margin-top: 2em;
      }
    }

    .head__information {
      position: fixed;
      top: 2rem;
      right: 2rem;
      display: flex;
      width: calc(100% - 12rem - 4rem);

      nav {
        margin-left: 4em;
      }
    }

    .head__title {
      margin-right: auto;
      font-weight: bold;
    }

    .head__navgation {
      // margin-top: 1em;

      &:first-child {
        margin-top: 0;
      }

      .navgation__group {
        font-weight: bold;
      }

      .navgation__link {

        li {
          vertical-align: bottom;

          a {
            // color: $color-black;

            &:before {
              content: '';
              display: inline-block;
              width: 0.8em;
              height: 0.8em;
              margin-right: 0.4em;
            }
          }
        }
      }

      .navgation__item {

        dl {
          display: flex;

          dt {
            width: 4.5em;
          }
        }
      }
    }
  }

  .p-flame {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .p-parts {
    width: 100%;
    min-height: 100vh;
    padding: 14rem 2rem 8rem 14rem;

    .parts__section {
      display: flex;

      margin-top: 7rem;
      padding-top: 7rem;
      border-top: solid 1px $color-black;

      &:first-child {
        margin-top: 0;
        // padding-top: 0;
        // border: none;
      }

      .section__title {
        width: 10rem;
        margin-right: 2rem;
        font-family: $font-famiry-sample;
        font-size: $font-size-xs;
        font-weight: bold;
      }

      .section__content {
        width: calc(100% - 12rem);
      }

      .section__block {
        margin-top: 3rem;

        &:first-child {
          margin-top: 0;
        }

        h3 {
          font-size: $font-size-xs;

          & + * {
            margin-top: 1rem;
          }
        }

        [class*='block__box'] {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 100px;
          min-height: 48px;
          background-color: $color-border;
          color: $color-white;
          font-family: $font-famiry-sample;
          font-size: $font-size-xs;

          &:nth-child(2n) {
            background-color: lighten($color-border, 2%);
          }

          &[class*='-h100'] {
            min-height: 100px;
          }

          &[class*='-circle'] {
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
