//===========================================
//  layout
//===========================================

//-------------------------------------------
//  ページ
//-------------------------------------------
$layout-max-width: 1600px;
$layout-min-width: 1120px;

$layout-clearance-width-lg: 160px;
$layout-clearance-width-sm: 36px;

$layout-clearance-height-lg: 12rem;
$layout-clearance-height-sm: 16px;

//-------------------------------------------
//  セクション
//-------------------------------------------
$layout-secction-lg: 96px;
$layout-secction-sm: 80px;
