//===========================================
//  transition
//===========================================
@mixin transition($duration: 0.4s, $delay: 0, $property: all, $timing: ease) {
  transition-property: $property;
  transition-duration: $duration;
  transition-delay: $delay;
  transition-timing-function: $timing;
  // backface-visibility: hidden;
}
