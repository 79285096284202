/*===========================================
    p-top
===========================================*/
#p-top {
  @mixin sectionInner {
    width: 100%;
    @include media-query(lg) {
      min-width: $layout-min-width;
      max-width: $layout-max-width;
      margin: 0 auto;
      padding: 0 $layout-clearance-width-lg;
    }
    @include media-query(sm) {
      padding: 0 $layout-clearance-width-sm;
    }

    h2, h3 {

      + * {
        margin-top: 1.4rem;
      }
    }

    h2 {
      margin-bottom: 4rem;
      @include media-query(lg) {
        height: 5.5rem;
      }
      @include media-query(sm) {
      }

      img {
        @include media-query(lg) {
          width: auto;
          height: 100%;
        }
      }
    }

    h3 {
      @include media-query(sm) {
        font-size: 1.6rem;
      }
    }

    > p {
      font-weight: bold;
      line-height: 2;
      letter-spacing: 0.1em;
      @include media-query(lg) {
        width: 80%;
      }
      @include media-query(sm) {
        width: 100%;
      }

      + * {
        margin-top: 5rem;
      }
    }
  }

  @mixin sectionLink {
    margin-top: 8rem !important;
  }

  .p-keyVisual {
    position: relative;
    display: flex;
    align-items: center;
    width: 100vw;
    @include media-query(sm) {
      max-height: 740px;
      height: 75vh;
    }

    .keyVisual__inner {
      @include sectionInner;
      @include media-query(lg) {
        padding: 11vh $layout-clearance-width-lg 8vh;
      }
      @include media-query(sm) {
        padding: 10vh 0 0 $layout-clearance-width-sm;
      }
    }

    .keyVisual__image {
      position: relative;
      width: 100%;

      > img {
        display: block;

        &.image__base {
          @include media-query(lg) {
            width: 55%;
          }
          @include media-query(sm) {
            width: 63%;
          }
        }
      }

      .img__slide {
        position: absolute;
        @include media-query(lg) {
          top: -9%;
          right: 7%;
          width: 59%;
        }
        @include media-query(sm) {
          top: -10%;
          right: -5%;
          width: 66.5%;
        }

        img {
          position: absolute;
          width: 100%;
        }
      }
    }

    .keyVisual__title {
      margin-top: 3rem;

      img {
        display: block;
        @include media-query(lg) {
          width: 35%;
        }
        @include media-query(sm) {
          width: 50%;
        }
      }
      // p {
      //   font-size: $font-size-sm;
      //   font-weight: bold;
      // }
      //
      // h1 {
      //   margin-top: 0.2rem;
      //   font-size: $font-size-xl;
      //   font-family: $font-family-fs;
      //   font-weight: bold;
      //   line-height: 1.6;
      //
      //   span {
      //     display: block;
      //     font-weight: bold;
      //   }
      // }
    }

    .keyVisual__decoration {
      position: absolute;
      @include media-query(lg) {
        bottom: -120px;
        right: -40px;
        max-width: 480px;
        width: 28vw;
      }
      @include media-query(sm) {
        bottom: -120px;
        right: -16px;
        width: 176px;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }


  .p-overview {
    margin-top: $layout-clearance-height-lg;

    .overview__inner {
      @include sectionInner;
    }

    .overview__title {
      letter-spacing: 0.3em;
      @include media-query(lg) {
        font-size: 3.2rem;
      }
      @include media-query(sm) {
        font-size: 2.4rem;
      }
    }

    .overview__text {
      margin-top: 3rem;
      font-weight: bold;
      letter-spacing: 0.1em;
      line-height: 2.6 !important;
      @include media-query(lg) {
        font-size: $font-size-xl;
      }
      @include media-query(sm) {
        font-size: $font-size-lg;
      }
    }

    .overview__link {
      @include sectionLink;
    }
  }


  .p-menu {
    margin-top: $layout-clearance-height-lg;

    .menu__inner {
      @include sectionInner;
    }

    .menu__list {
      display: flex;
      flex-wrap: wrap;

      .list__item {

        figure {

          img {
            display: block;
            width: 100%;
            @include media-query(sm) {
              transform: scale(1.2);
            }
          }

          figcaption {
            display: block;
            font-family: $font-family-fs;
            text-align: center;
            letter-spacing: -0.05em;
            @include media-query(lg) {
              margin-top: 0;
              font-size: $font-size-sm * 2;
            }
            @include media-query(sm) {
              margin-top: 0.5em;
              font-size: $font-size-lg;
            }
          }
        }
      }
    }

    .menu__link {
      @include sectionLink;
    }
  }


  .p-member {
    margin-top: $layout-clearance-height-lg;

    .member__inner {
      @include sectionInner;

      h2 {
        @include media-query(sm) {
          font-size: 4.4rem;
        }
      }
    }

    .member__image {
      width: 100%;

      img {
        display: block;
        width: 100%;
        @include media-query(sm) {
          transform: scale(1.2);
        }
      }

      .image__name {
        margin-top: 0;

        p {
          font-family: $font-family-fs;
          font-size: $font-size-sm * 2;
          text-align: center;
          letter-spacing: -0.03em;
        }
      }
    }

    .member__link {
      @include sectionLink;
    }
  }


  .p-howto {
    margin-top: $layout-clearance-height-lg;

    .howto__inner {
      @include sectionInner;
    }

    .howto__title {
      @include media-query(sm) {
        width: 70%;
      }
    }

    .howto__step {
      width: 100%;
      @include media-query(lg) {
        margin-top: 10rem !important;
      }

      .step__item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        width: 100%;
        margin-top: 6rem;
        @include media-query(lg) {
          padding: 0 0 0 8.5rem;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      .step__number {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        margin-left: -1rem;
        @include media-query(lg) {
          width: 6rem;
        }
        @include media-query(sm) {
          width: 5rem;
        }
      }

      .step__content {
        @include media-query(lg) {
          width: calc(100% - 60%);
        }
        @include media-query(sm) {
          width: 100%;
        }

        .content__title {
          font-weight: bold;
          line-height: 1;
          letter-spacing: 0.8em;
          white-space: nowrap;
          @include media-query(lg) {
            font-size: 3.75rem;
          }
          @include media-query(sm) {
            font-size: 3rem;
            padding-left: 6.2rem;
          }
        }

        .content__process {
          margin-top: 2rem;
          font-size: $font-size-lg;
          font-weight: bold;
          @include media-query(sm) {
            padding-left: 6.2rem;
          }
        }

        .content__text {
          margin-top: 2rem;
          font-weight: bold;
        }

        .content__image {
          @include media-query(lg) {
            display: none;
          }
          @include media-query(sm) {
            width: 100%;
            margin-top: 2rem;
          }


          img {
            display: block;
            width: 100%;
          }
        }
      }

      .step__image {
        @include media-query(lg) {
          position: relative;
          margin-top: -6.5%;
          margin-right: -4.5%;
          width: 60%;
        }
        @include media-query(sm) {
          display: none;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }

    .howto__end {
      margin-top: 9rem;

      span {
        padding-bottom: 0.8em;
        border-bottom: solid 10px $color-black;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 3.2;
      }
    }

    .howto__link {
      @include sectionLink;
    }
  }


  .p-works {
    margin-top: $layout-clearance-height-lg;

    .works__inner {
      @include sectionInner;
    }

    .works__navigation {
      font-family: $font-family-fs;
      font-size: $font-size-xs;
      letter-spacing: 0;
    }

    .works__list {
      margin: 4rem auto 0;
      @include media-query(lg) {
        min-width: $layout-min-width;
        // max-width: $layout-max-width;
        width: 100%;
      }
      @include media-query(sm) {
        width: 100%;
      }

      .list__item {
        position: relative;

        a {
          position: relative;
          display: block;
          overflow: hidden;
          width: 100%;
          padding-top: 60%;
          color: $color-black;

          .item__thumbnail {
            @include transition;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 0;
            transform: translate(-50%, -50%);
            width: 100%;
            object-fit: cover;
          }

          .item__information {
            @include transition;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            transform: translateY(101%);
            width: 100%;
            padding: 1em;
            background-color: rgba($color-primary, 0.8);
            @include media-query(lg) {
              justify-content: center;
            }
            @include media-query(sm) {
              justify-content: flex-end;
              height: 100%;
            }
          }

          .item__category {
            font-family: $font-family-fs;
            font-size: $font-size-sm;
          }

          .item__title {
            font-size: $font-size-xs;
          }

          &:hover {

            .item__thumbnail {
              transform: translate(-50%, -50%) scale(1.1);
            }

            .item__information {
              transform: translateY(0);
            }
          }
        }
      }
    }

    .works__link {
      @include sectionLink;
      margin-bottom: 14rem;
    }
  }
}
