/*===========================================
    c-icon
===========================================*/

%icon-border {
  @include transition;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 1px;
  background-color: $color-text;
}

$icon-size-mag: 4;

//-------------------------------------------

[class*="c-icon"] {
  @include transition;
  position: relative;
  display: block;
  width: 2em;
  height: 2em;
  cursor: pointer;

  a & {
    display: inline-block;
  }

  .icon__border {
    @extend %icon-border;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  //-----------------------------------------
  //  color
  //-----------------------------------------
  @mixin icon-color($backgroundColor) {

    .icon__border,
    &:before,
    &:after {
      background-color: $backgroundColor;
    }
  }

  &[class*="-black"] {
    @include icon-color($color-black)
  }

  &[class*="-white"] {
    @include icon-color($color-white)
  }

  &[class*="-primary"] {
    @include icon-color($color-primary)
  }

  &[class*="-secondary"] {
    @include icon-color($color-secondary)
  }


    //-----------------------------------------
    //  size
    //-----------------------------------------
    &[class*="-xl"] {
      width: 5rem;
      height: 5rem;
    }

    &[class*="-lg"] {
      width: 4rem;
      height: 4rem;
    }

    &[class*="-md"] {
      width: 3rem;
      height: 3rem;
    }

    &[class*="-sm"] {
      width: 2rem;
      height: 2rem;
    }

    &[class*="-xs"] {
      width: 1rem;
      height: 1rem;
    }


  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-menu"] {

    .icon__border {

      &:nth-child(1) {
        top: 35%;
      }

      &:nth-child(2) {
      }

      &:nth-child(3) {
        top: 65%;
      }
    }

    a:hover & {

      .icon__border {

        &:nth-child(1) {
          top: 30%;
        }

        &:nth-child(2) {
        }

        &:nth-child(3) {
          top: 70%;
        }
      }
    }

    .is-active > & {

      .icon__border {

        &:nth-child(1) {
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    a.is-active:hover > & {

      .icon__border {
        top: 50%;
      }
    }
  }


  //-----------------------------------------
  //  dot
  //-----------------------------------------
  &[class*="-dot"] {

    .icon__border {
      width: 4px;
      height: 4px;
      border-radius: 50%;

      &:nth-child(1) {
        left: 25%;
      }

      &:nth-child(2) {
        left: 50%;
      }

      &:nth-child(3) {
        left: 75%;
      }
    }

    .is-active > &,
    a:hover & {

      .icon__border {

        &:nth-child(1) {
          left: 20%;
        }

        &:nth-child(2) {
          left: 50%;
        }

        &:nth-child(3) {
          left: 80%;
        }
      }
    }
  }

  //-----------------------------------------
  //  toggle
  //-----------------------------------------
  &[class*="-toggle"] {

    &:before {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(-90deg);
    }

    &:after {
      @extend %icon-border;
    }

    a:hover & {

      &:before {
        transform: translate(-50%, -50%) rotate(0deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    .is-active > & {

      &:before {
        transform: translate(-50%, -50%);
      }

      &:after {
        opacity: 0;
      }
    }

    a.is-active:hover > & {

      // &:before {
      //   transform: translate(-50%, -50%) rotate(180deg);
      // }
    }
  }


  //-----------------------------------------
  //  cross
  //-----------------------------------------
  &[class*="-cross"] {

    &:before {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(135deg);
    }

    .is-active > &,
    a:hover & {

      &:before {
        transform: translate(-50%, -50%) rotate(135deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(225deg);
      }
    }
  }


  //-----------------------------------------
  //  plus
  //-----------------------------------------
  &[class*="-plus"] {

    &:before {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &:after {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(0deg);
    }

    .is-active > &,
    a:hover & {

      &:before {
        transform: translate(-50%, -50%) rotate(180deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }


  //-----------------------------------------
  //  minus
  //-----------------------------------------
  &[class*="-minus"] {

    &:before {
      @extend %icon-border;
      transform: translate(-50%, -50%) rotate(0deg);
    }

    .is-active > &,
    a:hover & {

      &:before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }


  //-----------------------------------------
  //  arrow
  //-----------------------------------------
  @mixin icon-arrow($translateX1, $translateX2, $translateY1, $translateY2, $rotate, $originBefore, $originAfter, $position) {

    &:before,
    &:after  {
      @extend %icon-border;
      @if $originBefore == right {
        top: $position;
        width: 30%;
        height: 1px;
      }
      @else if $originBefore == bottom {
        left: $position;
        width: 1px;
        height: 30%;
      }
    }

    &:before {
      transform: translate($translateX1, $translateY1) rotate(-$rotate);
      transform-origin: $originBefore;
    }

    &:after {
      transform: translate($translateX2, $translateY2) rotate($rotate);
      transform-origin: $originAfter;
      // background-color: $color-primary;
    }

    .is-active > & {
      transform: rotate(180deg);
    }

    .is-active:hover > & {
      @if $originBefore == right {
        transform: rotate(180deg) translateY($translateY1 / 2);
      }
      @else if $originBefore == bottom {
        transform: rotate(180deg) translateX($translateX1 / 2);
      }
    }

    a:hover & {
      @if $originBefore == right {
        transform: translateY($translateY1 / 2);
      }
      @else if $originBefore == bottom {
        transform: translateX($translateX1 / 2);
      }
    }
  }


  &[class*="-arrow-t"] {
    @include icon-arrow(-100%, 0, -50%, -50%, 45deg, right, left, 40%)
  }

  &[class*="-arrow-r"] {
    @include icon-arrow(50%, 50%, -100%, 0, 45deg, bottom, top, 60%)
  }

  &[class*="-arrow-b"] {
    @include icon-arrow(-100%, 0, 50%, 50%, -45deg, right, left, 60%)
  }

  &[class*="-arrow-l"] {
    @include icon-arrow(-50%, -50%, -100%, 0, -45deg, bottom, top, 40%)
  }
}
