/*===========================================
    l-header
===========================================*/
.l-header {

  .header__logo {
    position: fixed;
    z-index: 999;
    width: 32px;
    height: 32px;
    @include media-query(lg) {
      top: 48px;
      left: 48px;
    }
    @include media-query(sm) {
      top: $layout-clearance-width-sm;
      left: $layout-clearance-width-sm;
    }
  }
}
