/*===========================================
    js-scrollSwitch
===========================================*/
.js-scrollSwitch {
}

.js-scrollSwitch__item {
}

.js-scrollSwitch__imageWrap {
  position: relative;
}

.js-scrollSwitch__image {

  &.is-hide {
    opacity: 0;
  }

  &.is-clone,
  &.is-loop,
  &.is-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
