/*===========================================
    c-checkbox
===========================================*/

//-------------------------------------------

[class*="c-checkbox"] {
  position: relative;
  display: inline-block;
  padding-left: 1.8em;
  cursor: pointer;

  input {
    display: none;

    &:checked {

      & + .checkbox__icon {

        &:before {
          transform: scale(1);
        }

        &:after {
          opacity: 1;
        }
      }
    }

    &:disabled {

      & + .checkbox__icon {
        border: $form-input-border-type $form-input-border-width $form-input-border-color;
        background-color: $form-disabled-color;
      }
    }
  }

  .checkbox__icon {
    position: absolute;
    top: 0.2em;
    left: 0;
    display: block;
    width: 1.4em;
    height: 1.4em;
    background-color: $color-white;
    border: $form-input-border-type $form-input-border-width $form-input-border-color;
    vertical-align: middle;

    &:before,
    &:after {
      @include transition;
      content: '';
      position: absolute;
      display: block;
    }

    &:before {
      top: - #{$form-input-border-width};
      left: - #{$form-input-border-width};
      transform: scale(0);
      width: 100%;
      height: 100%;
      background-color: $form-focus-color;
      border: $form-input-border-type $form-input-border-width $form-focus-color;
    }

    &:after {
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      transform-origin: center;
      width: 0.3em;
      height: 0.8em;
      border-right: solid 2px $color-white;
      border-bottom: solid 2px $color-white;
      opacity: 0;
    }
  }


  //-----------------------------------------
  //  type
  //-----------------------------------------
  &[class*="-switch"] {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    input {

      &:checked {

        & + .checkbox__switch {
          background-color: lighten($form-focus-color, 30%);

          &:before {
            left: 50%;
            background-color: $form-focus-color;
          }
        }
      }

      &:disabled {

        & + .checkbox__switch {

          &:before {
            background-color: $form-disabled-color;
          }
        }
      }
    }

    .checkbox__switch {
      @include transition;
      position: relative;
      margin: 0 0.5em;
      width: 3em;
      height: 1em;
      background-color: $form-input-border-color;
      border-radius: 1em;

      &:before {
        @include transition;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        display: block;
        width: 1.5em;
        height: 1.5em;
        background-color: $color-white;
        border-radius: 50%;
      }
    }
  }


  //-----------------------------------------
  //  combination
  //-----------------------------------------
  &[class*="-button"] {
    padding: 0;
  }
}
