/*===========================================
    u-display
===========================================*/
.u-display {

  &--block {
    display: block !important;
  }

  &--inline {
    display: inline !important;
  }

  &--none {
    display: none !important;

    &-sm {
      @include media-query(sm) {
        display: none !important;
      }
    }

    &-md {
      @include media-query(md) {
        display: none !important;
      }
    }

    &-lg {
      @include media-query(lg) {
        display: none !important;
      }
    }
  }
}
