@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Fira+Code:400,600&display=swap");
@font-face {
  font-family: 'FandS_Gothic';
  src: url("../fonts/FandS_Gothic.eot?lt0hbo");
  src: url("../fonts/FandS_Gothic.eot?lt0hbo#iefix") format("embedded-opentype"), url("../fonts/FandS_Gothic.ttf?lt0hbo") format("truetype"), url("../fonts/FandS_Gothic.woff?lt0hbo") format("woff"), url("../fonts/FandS_Gothic.svg?lt0hbo#FandS_Gothic") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*===========================================
    reset
===========================================*/
html {
  overflow-y: auto;
}

body {
  position: relative;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

html, body, header, nav, footer, main, aside, section, article, nav, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, caption, mark, dl, dt, dd, ol, ul, li, fieldset, label, legend, option, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 100%;
  font-style: normal;
  font-weight: normal;
  vertical-align: top;
  text-align: left;
}

dl, dt, dd, ol, ul, li {
  list-style: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  *border-collapse: collapse;
  border-spacing: 0;
}

th, td {
  border: none;
  text-align: left;
  vertical-align: top;
}

q, blockquote {
  quotes: none;
}

iframe {
  display: block;
  width: 100%;
  overflow: hidden;
}

img {
  border: none;
  vertical-align: bottom;
}

a {
  border: none;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  outline: none;
  cursor: pointer;
}

a:active, a:focus {
  outline: none;
}

sup {
  position: relative;
  bottom: 0.7em;
  font-size: 71.5%;
  vertical-align: baseline;
}

sub {
  position: relative;
  top: 0.1em;
  font-size: 71.5%;
  vertical-align: baseline;
}

abbr, acronym {
  border-bottom: dotted 1px #ccc;
  cursor: help;
}

hr {
  display: none;
}

button,
input,
select,
textarea {
  border: none;
  outline: none;
  appearance: none;
  font-size: inherit;
  letter-spacing: inherit;
}

input[type="checkbox"] {
  appearance: checkbox;
  cursor: pointer;
}

input[type="radio"] {
  appearance: radio;
  cursor: pointer;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]:focus {
  outline-offset: -2px;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="send"],
input[type="reset"],
input[type="button"] {
  cursor: pointer;
}

input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

textarea {
  resize: none;
}

button, select {
  cursor: pointer;
}

/*===========================================
    base
===========================================*/
html {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

@media screen and (min-width: 769px), print {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

body {
  -webkit-overflow-scrolling: touch;
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #000;
  font-family: "游ゴシック", YuGothic, "Yu Gothic Medium", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-feature-settings: "palt" 1;
  letter-spacing: 0.01em;
  line-height: 1.8;
}

body * {
  text-align: justify;
  text-justify: inter-ideograph;
}

body a {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  color: #000;
}

body a:hover {
  text-decoration: underline;
}

body a:focus {
  text-decoration: none;
}

body a:active {
  text-decoration: none;
}

body b {
  font-weight: bold;
}

body em {
  font-weight: bold;
}

body strong {
  font-weight: bold;
}

body mark {
  background-color: #fafa32;
}

body s {
  text-decoration: line-through;
}

body del {
  text-decoration: line-through;
}

body small {
  font-size: 0.8em;
  color: #1a1a1a;
}

::selection {
  background: #fafa32;
}

/*===========================================
    animate
===========================================*/
.is-animated {
  animation-duration: 0.5s;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-direction:  normal;
  animation-fill-mode: both;
  animation-play-state:  running;
}

.is-animated.fx-infinite {
  animation-iteration-count: infinite;
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

.fx-fide-in {
  animation-name: fade-in;
}

.fx-fide-out {
  animation-name: fade-out;
}

@keyframes link-fs {
  0% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
  20% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
  100% {
    transform-origin: left top;
    transform: scale(1, 1);
  }
}

.fx-link-fs:hover:after {
  animation-name: link-fs;
}

/*===========================================
    l-header
===========================================*/
.l-header .header__logo {
  position: fixed;
  z-index: 999;
  width: 32px;
  height: 32px;
}

@media screen and (min-width: 769px), print {
  .l-header .header__logo {
    top: 48px;
    left: 48px;
  }
}

@media screen and (max-width: 768px) {
  .l-header .header__logo {
    top: 36px;
    left: 36px;
  }
}

/*===========================================
    l-footer
===========================================*/
.l-footer {
  width: 100%;
  background-color: #f1f1f1;
}

@media screen and (min-width: 769px), print {
  .l-footer .footer__inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 56px 48px;
  }
}

@media screen and (max-width: 768px) {
  .l-footer .footer__inner {
    padding: 48px 32px;
  }
}

@media screen and (min-width: 769px), print {
  .l-footer .footer__logo {
    width: 184px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 768px) {
  .l-footer .footer__logo {
    width: 40%;
    margin-bottom: 32px;
  }
}

.l-footer .footer__profile {
  font-size: 0.75rem;
}

@media screen and (min-width: 769px), print {
  .l-footer .footer__profile {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .l-footer .footer__profile {
    width: 100%;
    margin-bottom: 32px;
  }
}

.l-footer .footer__tel {
  margin-bottom: 32px;
}

.l-footer .footer__tel p {
  display: inline-block;
}

.l-footer .footer__copyright {
  font-size: 0.75rem;
  text-align: right;
}

@media screen and (min-width: 769px), print {
  .l-footer .footer__copyright {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .l-footer .footer__copyright {
    width: 100%;
  }
}

/*===========================================
    l-main
===========================================*/
.l-main {
  position: relative;
  overflow-x: hidden;
  width: 100vw;
}

.l-main .main__reference {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  opacity: 0.2;
}

.l-main .main__reference img {
  width: 100%;
}

/*===========================================
    c-breadcrumb
===========================================*/
[class*="c-breadcrumb"] {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
}

[class*="c-breadcrumb"] li {
  white-space: nowrap;
}

[class*="c-breadcrumb"] li:after {
  content: '>';
  padding: 0 0.6em;
}

[class*="c-breadcrumb"] li:last-child:after {
  content: none;
}

[class*="c-breadcrumb"] li a {
  color: #000;
  white-space: inherit;
}

[class*="c-breadcrumb"][class*="-scroll"] {
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: 100%;
  padding-bottom: 1em;
}

/*===========================================
    c-button
===========================================*/
[class*="c-button"] {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  position: relative;
  display: inline-flex;
  justify-content: justify-content;
  align-items: center;
  width: auto;
  height: calc(1em + 2em);
  padding: 1em 2.5em;
  background-color: #000;
  border: solid #000;
  border-width: 0;
  border-radius: 0;
  color: #fff;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

[class*="c-button"]:hover {
  text-decoration: none;
  opacity: 0.6;
}

[class*="c-button"].is-active,
input:checked + [class*="c-button"] {
  background-color: #fff;
  border: solid #000;
  border-width: 0;
  color: #000;
}

[class*="c-button"].is-disabled,
input:disabled + [class*="c-button"] {
  opacity: 0.6;
  pointer-events: none;
}

[class*="c-button"] [class*="button__icon"] {
  width: 1.5em;
  height: 1.5em;
}

[class*="c-button"] [class*="button__icon"][class*='--before'] {
  margin: 0 1.5em 0 -1.5em;
}

[class*="c-button"] [class*="button__icon"][class*='--after'] {
  margin: 0 -1.5em 0 1.5em;
}

[class*="c-button"] [class*="button__icon"] img {
  display: block;
  width: auto;
  height: 100%;
}

[class*="c-button"] input {
  display: none;
}

[class*="c-button"][class*="-xl"] {
  font-size: 1.4rem;
}

[class*="c-button"][class*="-lg"] {
  font-size: 1.125rem;
}

[class*="c-button"][class*="-md"] {
  font-size: 1rem;
}

[class*="c-button"][class*="-sm"] {
  font-size: 0.875rem;
}

[class*="c-button"][class*="-xs"] {
  font-size: 0.75rem;
}

[class*="c-button"][class*="-black"] {
  background-color: #000;
  color: #fff;
}

[class*="c-button"][class*="-black"].is-active {
  background-color: transparent;
  border: solid #000;
  border-width: 0;
  color: #000;
}

[class*="c-button"][class*="-white"] {
  background-color: #fff;
  color: #000;
}

[class*="c-button"][class*="-white"].is-active {
  background-color: transparent;
  border: solid #fff;
  border-width: 0;
  color: #fff;
}

[class*="c-button"][class*="-primary"] {
  background-color: #FAFA32;
  color: #fff;
}

[class*="c-button"][class*="-primary"].is-active {
  background-color: transparent;
  border: solid #FAFA32;
  border-width: 0;
  color: #FAFA32;
}

[class*="c-button"][class*="-secondary"] {
  background-color: #FF6D60;
  color: #fff;
}

[class*="c-button"][class*="-secondary"].is-active {
  background-color: transparent;
  border: solid #FF6D60;
  border-width: 0;
  color: #FF6D60;
}

[class*="c-button"][class*="-full"] {
  display: flex;
  justify-content: center;
  width: 100%;
}

/*===========================================
    c-card
===========================================*/
[class*="c-card"] {
  background-color: #fff;
}

[class*="c-card"] .card__image {
  position: relative;
  overflow: hidden;
  width: 100%;
}

[class*="c-card"] .card__image:before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 61.8%;
}

[class*="c-card"] .card__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}

[class*="c-card"] .card__head {
  border-bottom: solid 1px #ddd;
}

[class*="c-card"] .card__body > *:first-child {
  margin-top: 0 !important;
}

[class*="c-card"] .card__foot {
  border-top: solid 1px #ddd;
}

[class*="c-card"] .card__caption {
  font-size: 2em;
}

[class*="c-card"] .card__credit {
  color: #999999;
  font-size: 0.75em;
  font-style: italic;
}

[class*="c-card"][class*="-xl"] {
  font-size: 1.4rem;
}

[class*="c-card"][class*="-xl"] .card__body {
  padding: 2.1rem 2.52rem;
}

[class*="c-card"][class*="-xl"] .card__body > * {
  margin-top: 0.7rem;
}

[class*="c-card"][class*="-xl"] .card__head,
[class*="c-card"][class*="-xl"] .card__foot {
  padding: 1.05rem 2.52rem;
}

[class*="c-card"][class*="-lg"] {
  font-size: 1.125rem;
}

[class*="c-card"][class*="-lg"] .card__body {
  padding: 1.6875rem 2.025rem;
}

[class*="c-card"][class*="-lg"] .card__body > * {
  margin-top: 0.5625rem;
}

[class*="c-card"][class*="-lg"] .card__head,
[class*="c-card"][class*="-lg"] .card__foot {
  padding: 0.84375rem 2.025rem;
}

[class*="c-card"][class*="-md"] {
  font-size: 1rem;
}

[class*="c-card"][class*="-md"] .card__body {
  padding: 1.5rem 1.8rem;
}

[class*="c-card"][class*="-md"] .card__body > * {
  margin-top: 0.5rem;
}

[class*="c-card"][class*="-md"] .card__head,
[class*="c-card"][class*="-md"] .card__foot {
  padding: 0.75rem 1.8rem;
}

[class*="c-card"][class*="-sm"] {
  font-size: 0.875rem;
}

[class*="c-card"][class*="-sm"] .card__body {
  padding: 1.3125rem 1.575rem;
}

[class*="c-card"][class*="-sm"] .card__body > * {
  margin-top: 0.4375rem;
}

[class*="c-card"][class*="-sm"] .card__head,
[class*="c-card"][class*="-sm"] .card__foot {
  padding: 0.65625rem 1.575rem;
}

[class*="c-card"][class*="-xs"] {
  font-size: 0.75rem;
}

[class*="c-card"][class*="-xs"] .card__body {
  padding: 1.125rem 1.35rem;
}

[class*="c-card"][class*="-xs"] .card__body > * {
  margin-top: 0.375rem;
}

[class*="c-card"][class*="-xs"] .card__head,
[class*="c-card"][class*="-xs"] .card__foot {
  padding: 0.5625rem 1.35rem;
}

[class*="c-card"][class*="-shadow"] {
  box-shadow: 1em 1em 1em rgba(0, 0, 0, 0.05);
}

/*===========================================
    c-checkbox
===========================================*/
[class*="c-checkbox"] {
  position: relative;
  display: inline-block;
  padding-left: 1.8em;
  cursor: pointer;
}

[class*="c-checkbox"] input {
  display: none;
}

[class*="c-checkbox"] input:checked + .checkbox__icon:before {
  transform: scale(1);
}

[class*="c-checkbox"] input:checked + .checkbox__icon:after {
  opacity: 1;
}

[class*="c-checkbox"] input:disabled + .checkbox__icon {
  border: solid 1px #ddd;
  background-color: #eaeaea;
}

[class*="c-checkbox"] .checkbox__icon {
  position: absolute;
  top: 0.2em;
  left: 0;
  display: block;
  width: 1.4em;
  height: 1.4em;
  background-color: #fff;
  border: solid 1px #ddd;
  vertical-align: middle;
}

[class*="c-checkbox"] .checkbox__icon:before, [class*="c-checkbox"] .checkbox__icon:after {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  content: '';
  position: absolute;
  display: block;
}

[class*="c-checkbox"] .checkbox__icon:before {
  top: -1px;
  left: -1px;
  transform: scale(0);
  width: 100%;
  height: 100%;
  background-color: #FAFA32;
  border: solid 1px #FAFA32;
}

[class*="c-checkbox"] .checkbox__icon:after {
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: center;
  width: 0.3em;
  height: 0.8em;
  border-right: solid 2px #fff;
  border-bottom: solid 2px #fff;
  opacity: 0;
}

[class*="c-checkbox"][class*="-switch"] {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

[class*="c-checkbox"][class*="-switch"] input:checked + .checkbox__switch {
  background-color: #fefec7;
}

[class*="c-checkbox"][class*="-switch"] input:checked + .checkbox__switch:before {
  left: 50%;
  background-color: #FAFA32;
}

[class*="c-checkbox"][class*="-switch"] input:disabled + .checkbox__switch:before {
  background-color: #eaeaea;
}

[class*="c-checkbox"][class*="-switch"] .checkbox__switch {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  position: relative;
  margin: 0 0.5em;
  width: 3em;
  height: 1em;
  background-color: #ddd;
  border-radius: 1em;
}

[class*="c-checkbox"][class*="-switch"] .checkbox__switch:before {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  display: block;
  width: 1.5em;
  height: 1.5em;
  background-color: #fff;
  border-radius: 50%;
}

[class*="c-checkbox"][class*="-button"] {
  padding: 0;
}

/*===========================================
    c-heading
===========================================*/
[class*="c-heading"] {
  position: relative;
  color: #000;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.2em;
  font-feature-settings: "palt";
}

[class*="c-heading"][class*="-xl"] {
  font-size: 2.8rem;
}

[class*="c-heading"][class*="-lg"] {
  font-size: 2.25rem;
}

[class*="c-heading"][class*="-md"] {
  font-size: 2rem;
}

[class*="c-heading"][class*="-sm"] {
  font-size: 1.75rem;
}

[class*="c-heading"][class*="-xs"] {
  font-size: 1.5rem;
}

[class*="c-heading"][class*="-section"] {
  font-family: "FandS_Gothic", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif !important;
  text-indent: -0.1em;
  white-space: nowrap;
}

@media screen and (min-width: 769px), print {
  [class*="c-heading"][class*="-section"] {
    font-size: 6.5rem;
    letter-spacing: 0.1em;
  }
}

@media screen and (max-width: 768px) {
  [class*="c-heading"][class*="-section"] {
    font-size: 6.5rem;
    letter-spacing: 0.04em;
    line-height: 1.4;
  }
}

[class*="c-heading"][class*="-black"] {
  color: #000;
}

[class*="c-heading"][class*="-white"] {
  color: #fff;
}

[class*="c-heading"][class*="-primary"] {
  color: #FAFA32;
}

[class*="c-heading"][class*="-secondary"] {
  color: #FF6D60;
}

[class*="c-heading"][class*="-align-l"] {
  text-align: left;
}

[class*="c-heading"][class*="-align-c"] {
  text-align: center;
}

[class*="c-heading"][class*="-align-r"] {
  text-align: right;
}

[class*="c-heading"] [class*="heading__sub"] {
  color: #000;
  font-size: 0.5em;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  vertical-align: middle;
}

[class*="c-heading"] [class*="heading__sub"][class*="-top"] {
  display: block;
  margin-bottom: 0.5em;
}

[class*="c-heading"] [class*="heading__sub"][class*="-bottom"] {
  display: block;
  margin-top: 0.5em;
}

[class*="c-heading"] [class*="heading__sub"][class*="-before"] {
  display: inline-block;
  margin-right: 1em;
}

[class*="c-heading"] [class*="heading__sub"][class*="-after"] {
  display: inline-block;
  margin-left: 1em;
}

[class*="c-heading"] [class*="heading__icon"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
}

[class*="c-heading"] [class*="heading__icon"] img {
  display: block;
  width: auto;
  height: 100%;
}

[class*="c-heading"] [class*="heading__icon"][class*="--before"] {
  margin-right: 0.5em;
}

[class*="c-heading"] [class*="heading__icon"][class*="--after"] {
  margin-left: 0.5em;
}

/*===========================================
    c-icon
===========================================*/
[class*="c-icon"] .icon__border, [class*="c-icon"][class*="-toggle"]:before, [class*="c-icon"][class*="-toggle"]:after, [class*="c-icon"][class*="-cross"]:before, [class*="c-icon"][class*="-cross"]:after, [class*="c-icon"][class*="-plus"]:before, [class*="c-icon"][class*="-plus"]:after, [class*="c-icon"][class*="-minus"]:before, [class*="c-icon"][class*="-arrow-t"]:before, [class*="c-icon"][class*="-arrow-t"]:after, [class*="c-icon"][class*="-arrow-r"]:before, [class*="c-icon"][class*="-arrow-r"]:after, [class*="c-icon"][class*="-arrow-b"]:before, [class*="c-icon"][class*="-arrow-b"]:after, [class*="c-icon"][class*="-arrow-l"]:before, [class*="c-icon"][class*="-arrow-l"]:after {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 1px;
  background-color: #000;
}

[class*="c-icon"] {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  position: relative;
  display: block;
  width: 2em;
  height: 2em;
  cursor: pointer;
}

a [class*="c-icon"] {
  display: inline-block;
}

[class*="c-icon"] img {
  display: block;
  width: 100%;
  height: 100%;
}

[class*="c-icon"][class*="-black"] .icon__border, [class*="c-icon"][class*="-black"]:before, [class*="c-icon"][class*="-black"]:after {
  background-color: #000;
}

[class*="c-icon"][class*="-white"] .icon__border, [class*="c-icon"][class*="-white"]:before, [class*="c-icon"][class*="-white"]:after {
  background-color: #fff;
}

[class*="c-icon"][class*="-primary"] .icon__border, [class*="c-icon"][class*="-primary"]:before, [class*="c-icon"][class*="-primary"]:after {
  background-color: #FAFA32;
}

[class*="c-icon"][class*="-secondary"] .icon__border, [class*="c-icon"][class*="-secondary"]:before, [class*="c-icon"][class*="-secondary"]:after {
  background-color: #FF6D60;
}

[class*="c-icon"][class*="-xl"] {
  width: 5rem;
  height: 5rem;
}

[class*="c-icon"][class*="-lg"] {
  width: 4rem;
  height: 4rem;
}

[class*="c-icon"][class*="-md"] {
  width: 3rem;
  height: 3rem;
}

[class*="c-icon"][class*="-sm"] {
  width: 2rem;
  height: 2rem;
}

[class*="c-icon"][class*="-xs"] {
  width: 1rem;
  height: 1rem;
}

[class*="c-icon"][class*="-menu"] .icon__border:nth-child(1) {
  top: 35%;
}

[class*="c-icon"][class*="-menu"] .icon__border:nth-child(3) {
  top: 65%;
}

a:hover [class*="c-icon"][class*="-menu"] .icon__border:nth-child(1) {
  top: 30%;
}

a:hover [class*="c-icon"][class*="-menu"] .icon__border:nth-child(3) {
  top: 70%;
}

.is-active > [class*="c-icon"][class*="-menu"] .icon__border:nth-child(1) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.is-active > [class*="c-icon"][class*="-menu"] .icon__border:nth-child(2) {
  opacity: 0;
}

.is-active > [class*="c-icon"][class*="-menu"] .icon__border:nth-child(3) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

a.is-active:hover > [class*="c-icon"][class*="-menu"] .icon__border {
  top: 50%;
}

[class*="c-icon"][class*="-dot"] .icon__border {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

[class*="c-icon"][class*="-dot"] .icon__border:nth-child(1) {
  left: 25%;
}

[class*="c-icon"][class*="-dot"] .icon__border:nth-child(2) {
  left: 50%;
}

[class*="c-icon"][class*="-dot"] .icon__border:nth-child(3) {
  left: 75%;
}

.is-active > [class*="c-icon"][class*="-dot"] .icon__border:nth-child(1),
a:hover [class*="c-icon"][class*="-dot"] .icon__border:nth-child(1) {
  left: 20%;
}

.is-active > [class*="c-icon"][class*="-dot"] .icon__border:nth-child(2),
a:hover [class*="c-icon"][class*="-dot"] .icon__border:nth-child(2) {
  left: 50%;
}

.is-active > [class*="c-icon"][class*="-dot"] .icon__border:nth-child(3),
a:hover [class*="c-icon"][class*="-dot"] .icon__border:nth-child(3) {
  left: 80%;
}

[class*="c-icon"][class*="-toggle"]:before {
  transform: translate(-50%, -50%) rotate(-90deg);
}

a:hover [class*="c-icon"][class*="-toggle"]:before {
  transform: translate(-50%, -50%) rotate(0deg);
}

a:hover [class*="c-icon"][class*="-toggle"]:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.is-active > [class*="c-icon"][class*="-toggle"]:before {
  transform: translate(-50%, -50%);
}

.is-active > [class*="c-icon"][class*="-toggle"]:after {
  opacity: 0;
}

[class*="c-icon"][class*="-cross"]:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

[class*="c-icon"][class*="-cross"]:after {
  transform: translate(-50%, -50%) rotate(135deg);
}

.is-active > [class*="c-icon"][class*="-cross"]:before,
a:hover [class*="c-icon"][class*="-cross"]:before {
  transform: translate(-50%, -50%) rotate(135deg);
}

.is-active > [class*="c-icon"][class*="-cross"]:after,
a:hover [class*="c-icon"][class*="-cross"]:after {
  transform: translate(-50%, -50%) rotate(225deg);
}

[class*="c-icon"][class*="-plus"]:before {
  transform: translate(-50%, -50%) rotate(90deg);
}

[class*="c-icon"][class*="-plus"]:after {
  transform: translate(-50%, -50%) rotate(0deg);
}

.is-active > [class*="c-icon"][class*="-plus"]:before,
a:hover [class*="c-icon"][class*="-plus"]:before {
  transform: translate(-50%, -50%) rotate(180deg);
}

.is-active > [class*="c-icon"][class*="-plus"]:after,
a:hover [class*="c-icon"][class*="-plus"]:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

[class*="c-icon"][class*="-minus"]:before {
  transform: translate(-50%, -50%) rotate(0deg);
}

.is-active > [class*="c-icon"][class*="-minus"]:before,
a:hover [class*="c-icon"][class*="-minus"]:before {
  transform: translate(-50%, -50%) rotate(180deg);
}

[class*="c-icon"][class*="-arrow-t"]:before, [class*="c-icon"][class*="-arrow-t"]:after {
  top: 40%;
  width: 30%;
  height: 1px;
}

[class*="c-icon"][class*="-arrow-t"]:before {
  transform: translate(-100%, -50%) rotate(-45deg);
  transform-origin: right;
}

[class*="c-icon"][class*="-arrow-t"]:after {
  transform: translate(0, -50%) rotate(45deg);
  transform-origin: left;
}

.is-active > [class*="c-icon"][class*="-arrow-t"] {
  transform: rotate(180deg);
}

.is-active:hover > [class*="c-icon"][class*="-arrow-t"] {
  transform: rotate(180deg) translateY(-25%);
}

a:hover [class*="c-icon"][class*="-arrow-t"] {
  transform: translateY(-25%);
}

[class*="c-icon"][class*="-arrow-r"]:before, [class*="c-icon"][class*="-arrow-r"]:after {
  left: 60%;
  width: 1px;
  height: 30%;
}

[class*="c-icon"][class*="-arrow-r"]:before {
  transform: translate(50%, -100%) rotate(-45deg);
  transform-origin: bottom;
}

[class*="c-icon"][class*="-arrow-r"]:after {
  transform: translate(50%, 0) rotate(45deg);
  transform-origin: top;
}

.is-active > [class*="c-icon"][class*="-arrow-r"] {
  transform: rotate(180deg);
}

.is-active:hover > [class*="c-icon"][class*="-arrow-r"] {
  transform: rotate(180deg) translateX(25%);
}

a:hover [class*="c-icon"][class*="-arrow-r"] {
  transform: translateX(25%);
}

[class*="c-icon"][class*="-arrow-b"]:before, [class*="c-icon"][class*="-arrow-b"]:after {
  top: 60%;
  width: 30%;
  height: 1px;
}

[class*="c-icon"][class*="-arrow-b"]:before {
  transform: translate(-100%, 50%) rotate(45deg);
  transform-origin: right;
}

[class*="c-icon"][class*="-arrow-b"]:after {
  transform: translate(0, 50%) rotate(-45deg);
  transform-origin: left;
}

.is-active > [class*="c-icon"][class*="-arrow-b"] {
  transform: rotate(180deg);
}

.is-active:hover > [class*="c-icon"][class*="-arrow-b"] {
  transform: rotate(180deg) translateY(25%);
}

a:hover [class*="c-icon"][class*="-arrow-b"] {
  transform: translateY(25%);
}

[class*="c-icon"][class*="-arrow-l"]:before, [class*="c-icon"][class*="-arrow-l"]:after {
  left: 40%;
  width: 1px;
  height: 30%;
}

[class*="c-icon"][class*="-arrow-l"]:before {
  transform: translate(-50%, -100%) rotate(45deg);
  transform-origin: bottom;
}

[class*="c-icon"][class*="-arrow-l"]:after {
  transform: translate(-50%, 0) rotate(-45deg);
  transform-origin: top;
}

.is-active > [class*="c-icon"][class*="-arrow-l"] {
  transform: rotate(180deg);
}

.is-active:hover > [class*="c-icon"][class*="-arrow-l"] {
  transform: rotate(180deg) translateX(-25%);
}

a:hover [class*="c-icon"][class*="-arrow-l"] {
  transform: translateX(-25%);
}

/*===========================================
    c-input
===========================================*/
[class*="c-input"] {
  position: relative;
  display: inline-block;
}

[class*="c-input"] input[type="text"],
[class*="c-input"] input[type="password"],
[class*="c-input"] input[type="tel"],
[class*="c-input"] input[type="url"],
[class*="c-input"] input[type="email"],
[class*="c-input"] input[type="date"],
[class*="c-input"] input[type="time"],
[class*="c-input"] input[type="search"],
[class*="c-input"] textarea,
[class*="c-input"] select {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  position: relative;
  display: block;
  width: 100%;
  height: calc(1em + 2em);
  padding: 0.6em 1em;
  border: solid 1px #ddd;
  border-radius: 0;
  background-color: #fff;
  font-size: 1rem;
}

[class*="c-input"] input[type="text"]:focus,
[class*="c-input"] input[type="password"]:focus,
[class*="c-input"] input[type="tel"]:focus,
[class*="c-input"] input[type="url"]:focus,
[class*="c-input"] input[type="email"]:focus,
[class*="c-input"] input[type="date"]:focus,
[class*="c-input"] input[type="time"]:focus,
[class*="c-input"] input[type="search"]:focus,
[class*="c-input"] textarea:focus,
[class*="c-input"] select:focus {
  z-index: 1;
  border: solid 1px #FAFA32;
  box-shadow: 0 0 0.5em rgba(250, 250, 50, 0.4);
}

[class*="c-input"] input[type="text"]:disabled,
[class*="c-input"] input[type="password"]:disabled,
[class*="c-input"] input[type="tel"]:disabled,
[class*="c-input"] input[type="url"]:disabled,
[class*="c-input"] input[type="email"]:disabled,
[class*="c-input"] input[type="date"]:disabled,
[class*="c-input"] input[type="time"]:disabled,
[class*="c-input"] input[type="search"]:disabled,
[class*="c-input"] textarea:disabled,
[class*="c-input"] select:disabled {
  background-color: #eaeaea;
  color: #cccccc;
}

[class*="c-input"] input[type="text"]::placeholder,
[class*="c-input"] input[type="password"]::placeholder,
[class*="c-input"] input[type="tel"]::placeholder,
[class*="c-input"] input[type="url"]::placeholder,
[class*="c-input"] input[type="email"]::placeholder,
[class*="c-input"] input[type="date"]::placeholder,
[class*="c-input"] input[type="time"]::placeholder,
[class*="c-input"] input[type="search"]::placeholder,
[class*="c-input"] textarea::placeholder,
[class*="c-input"] select::placeholder {
  color: #cccccc;
}

[class*="c-input"] textarea {
  min-height: 5em;
}

[class*="c-input"] .input__select {
  position: relative;
}

[class*="c-input"] .input__select:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1.5em;
  z-index: 2;
  transform: translateY(-50%);
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6.9px 4px 0 4px;
  border-color: #000 transparent transparent transparent;
}

[class*="c-input"][class*="-line"] .input__effect {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ddd;
}

[class*="c-input"][class*="-line"] .input__effect:before {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #FAFA32;
}

[class*="c-input"][class*="-line"] input[type="text"],
[class*="c-input"][class*="-line"] input[type="password"],
[class*="c-input"][class*="-line"] input[type="tel"],
[class*="c-input"][class*="-line"] input[type="url"],
[class*="c-input"][class*="-line"] input[type="email"],
[class*="c-input"][class*="-line"] input[type="date"],
[class*="c-input"][class*="-line"] input[type="time"],
[class*="c-input"][class*="-line"] input[type="search"],
[class*="c-input"][class*="-line"] textarea,
[class*="c-input"][class*="-line"] select {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  display: block;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border: none;
}

[class*="c-input"][class*="-line"] input[type="text"]:focus,
[class*="c-input"][class*="-line"] input[type="password"]:focus,
[class*="c-input"][class*="-line"] input[type="tel"]:focus,
[class*="c-input"][class*="-line"] input[type="url"]:focus,
[class*="c-input"][class*="-line"] input[type="email"]:focus,
[class*="c-input"][class*="-line"] input[type="date"]:focus,
[class*="c-input"][class*="-line"] input[type="time"]:focus,
[class*="c-input"][class*="-line"] input[type="search"]:focus,
[class*="c-input"][class*="-line"] textarea:focus,
[class*="c-input"][class*="-line"] select:focus {
  border-color: #ddd;
  box-shadow: none;
}

[class*="c-input"][class*="-line"] input[type="text"]:focus + .input__effect:before,
[class*="c-input"][class*="-line"] input[type="password"]:focus + .input__effect:before,
[class*="c-input"][class*="-line"] input[type="tel"]:focus + .input__effect:before,
[class*="c-input"][class*="-line"] input[type="url"]:focus + .input__effect:before,
[class*="c-input"][class*="-line"] input[type="email"]:focus + .input__effect:before,
[class*="c-input"][class*="-line"] input[type="date"]:focus + .input__effect:before,
[class*="c-input"][class*="-line"] input[type="time"]:focus + .input__effect:before,
[class*="c-input"][class*="-line"] input[type="search"]:focus + .input__effect:before,
[class*="c-input"][class*="-line"] textarea:focus + .input__effect:before,
[class*="c-input"][class*="-line"] select:focus + .input__effect:before {
  width: 100%;
}

[class*="c-input"][class*="-line"] input[type="text"]:disabled .input__effect,
[class*="c-input"][class*="-line"] input[type="password"]:disabled .input__effect,
[class*="c-input"][class*="-line"] input[type="tel"]:disabled .input__effect,
[class*="c-input"][class*="-line"] input[type="url"]:disabled .input__effect,
[class*="c-input"][class*="-line"] input[type="email"]:disabled .input__effect,
[class*="c-input"][class*="-line"] input[type="date"]:disabled .input__effect,
[class*="c-input"][class*="-line"] input[type="time"]:disabled .input__effect,
[class*="c-input"][class*="-line"] input[type="search"]:disabled .input__effect,
[class*="c-input"][class*="-line"] textarea:disabled .input__effect,
[class*="c-input"][class*="-line"] select:disabled .input__effect {
  background-color: #eaeaea;
}

[class*="c-input"][class*="-line"] input[type="text"]::placeholder,
[class*="c-input"][class*="-line"] input[type="password"]::placeholder,
[class*="c-input"][class*="-line"] input[type="tel"]::placeholder,
[class*="c-input"][class*="-line"] input[type="url"]::placeholder,
[class*="c-input"][class*="-line"] input[type="email"]::placeholder,
[class*="c-input"][class*="-line"] input[type="date"]::placeholder,
[class*="c-input"][class*="-line"] input[type="time"]::placeholder,
[class*="c-input"][class*="-line"] input[type="search"]::placeholder,
[class*="c-input"][class*="-line"] textarea::placeholder,
[class*="c-input"][class*="-line"] select::placeholder {
  color: #cccccc;
}

[class*="c-input"][class*="-icon"] input[type="text"],
[class*="c-input"][class*="-icon"] input[type="password"],
[class*="c-input"][class*="-icon"] input[type="tel"],
[class*="c-input"][class*="-icon"] input[type="url"],
[class*="c-input"][class*="-icon"] input[type="email"],
[class*="c-input"][class*="-icon"] input[type="date"],
[class*="c-input"][class*="-icon"] input[type="time"],
[class*="c-input"][class*="-icon"] input[type="search"],
[class*="c-input"][class*="-icon"] textarea,
[class*="c-input"][class*="-icon"] select {
  padding-left: 3.5em;
}

[class*="c-input"][class*="-icon"] .input__icon {
  position: absolute;
  top: 50%;
  left: 1em;
  z-index: 1;
  transform: translateY(-50%);
  width: 1.5em;
  height: 1.5em;
}

/*===========================================
    c-button
===========================================*/
[class*="c-label"] {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0.2em 0.4em;
  background-color: #000;
  border: solid #000;
  border-width: 0;
  border-radius: 0;
  color: #fff;
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;
}

[class*="c-label"][class*="-xl"] {
  font-size: 1.4rem;
}

[class*="c-label"][class*="-lg"] {
  font-size: 1.125rem;
}

[class*="c-label"][class*="-md"] {
  font-size: 1rem;
}

[class*="c-label"][class*="-sm"] {
  font-size: 0.875rem;
}

[class*="c-label"][class*="-xs"] {
  font-size: 0.75rem;
}

[class*="c-label"][class*="-black"] {
  background-color: #000;
  color: #fff;
}

[class*="c-label"][class*="-white"] {
  background-color: #fff;
  color: #000;
}

[class*="c-label"][class*="-primary"] {
  background-color: #FAFA32;
  color: #fff;
}

[class*="c-label"][class*="-secondary"] {
  background-color: #FF6D60;
  color: #fff;
}

[class*="c-label"][class*="-badge"] {
  min-width: 2em;
  min-height: 2em;
  border-radius: 1em;
}

/*===========================================
    c-link
===========================================*/
[class*="c-link"] {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  color: #000;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

[class*="c-link"]:hover {
  opacity: 0.6;
}

[class*="c-link"].is-disabled {
  opacity: 0.6;
  pointer-events: none;
}

[class*="c-link"][class*="-icon"] i, [class*="c-link"][class*="-icon"] img {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  vertical-align: middle;
}

[class*="c-link"][class*="-bkimg"]:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  background-image: url("http://placehold.it/64x64");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

[class*="c-link"][class*="-fs"] {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  padding: 0;
  color: #000;
  font-size: 0.8rem;
  font-family: "FandS_Gothic", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif !important;
  line-height: 1.4;
  letter-spacing: 0.05em;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

[class*="c-link"][class*="-fs"] i {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1em;
}

[class*="c-link"][class*="-fs"].is-active:after {
  content: '';
  display: block;
  width: 100%;
  height: 1.5em;
  margin-top: 0.3em;
  background-color: #FAFA32;
}

[class*="c-link"][class*="-fs"]:hover {
  opacity: 1;
}

[class*="c-link"][class*="-fs"]:hover:after {
  animation-duration: 0.4s;
  animation-delay: 0;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: nomal;
  animation-fill-mode: both;
  animation-play-state:   running;
  animation-name: link-fs;
  content: '';
  display: block;
  width: 100%;
  height: 1.5em;
  margin-top: 0.3em;
  background-color: #FAFA32;
}

[class*="c-link"][class*="-fs"][class*="-100"]:after {
  height: 8rem;
}

/*===========================================
    c-list
===========================================*/
[class*="c-list"] li {
  position: relative;
  list-style-position: inside;
}

[class*="c-list"] > li + li {
  margin-top: 1em;
}

[class*="c-list"] > li > ul,
[class*="c-list"] > li > ol {
  margin-top: 1em;
}

[class*="c-list"] > li .list__block {
  margin-top: 1em;
  margin-left: 1em;
}

[class*="c-list"] > li .list__icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

[class*="c-list"][class*="-disc"] > li {
  padding-left: 1em;
  text-indent: -1em;
  list-style-type: disc;
}

[class*="c-list"][class*="-number"] > li {
  padding-left: 1.8em;
  text-indent: -1.8em;
  list-style-type: decimal-leading-zero;
}

[class*="c-list"][class*="-icon"] > li {
  padding-left: 1.4em;
  text-indent: -1.4em;
}

[class*="c-list"][class*="-icon"] > li i, [class*="c-list"][class*="-icon"] > li img {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  font-size: 1em;
  vertical-align: baseline;
}

[class*="c-list"][class*="-bkimg"] > li {
  padding-left: 1.4em;
  text-indent: -1.4em;
}

[class*="c-list"][class*="-bkimg"] > li:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  background-image: url("http://placehold.it/64x64");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

[class*="c-list"][class*="-link"] > li a {
  position: relative;
  display: block;
  padding-right: 3em;
}

[class*="c-list"][class*="-link"] > li a i, [class*="c-list"][class*="-link"] > li a img {
  position: absolute;
  top: 50%;
  right: 0;
  display: inline-block;
  width: 2em;
  height: 2em;
  margin-top: -1em;
  font-size: 1em;
}

[class*="c-list"][class*="-line"] > li {
  margin-top: 0;
  padding: 1em 0;
  border-bottom: solid 1px #ddd;
}

[class*="c-list"][class*="-line"] > li:first-child {
  border-top: solid 1px #ddd;
}

[class*="c-list"][class*="-line"] > li > a {
  padding-top: 1em;
  padding-bottom: 1em;
}

/*===========================================
    c-navigation
===========================================*/
[class*="c-navigation"] {
  padding-bottom: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
}

[class*="c-navigation"] .navigation__list {
  display: flex;
}

[class*="c-navigation"] .navigation__list li {
  margin-left: 4em;
}

[class*="c-navigation"] .navigation__list li:first-child {
  margin-left: 0;
}

[class*="c-navigation"] .navigation__list li a {
  position: relative;
  display: block;
  height: calc(1em + 2em);
  padding: 1em 0;
  text-decoration: none;
}

[class*="c-navigation"][class*="-line"] .navigation__list li {
  border-bottom: solid 1px #ddd;
}

[class*="c-navigation"][class*="-line"] .navigation__list li a:before {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
}

[class*="c-navigation"][class*="-line"] .navigation__list li a.is-active:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #FAFA32;
}

[class*="c-navigation"][class*="-box"] {
  padding-bottom: 0;
  border: solid 1px #ddd;
}

[class*="c-navigation"][class*="-box"] .navigation__list {
  display: inline-flex;
}

[class*="c-navigation"][class*="-box"] .navigation__list li {
  border-left: solid 1px #ddd;
}

[class*="c-navigation"][class*="-box"] .navigation__list li a {
  display: flex;
  justify-content: center;
  align-items: center;
}

[class*="c-navigation"][class*="-box"] .navigation__list li a.is-active {
  background-color: #FAFA32;
  color: #fff;
}

/*===========================================
    c-note
===========================================*/
[class*="c-note"] {
  padding-left: 1.4em;
  font-size: 0.875rem;
  text-indent: -1.4em;
}

[class*="c-note"][class*="-number"] {
  padding-left: 2em;
  text-indent: -2em;
}

[class*="c-note"][class*="-number"]:before {
  content: none;
}

/*===========================================
    c-pagenation
===========================================*/
[class*="c-pagenation"] {
  display: flex;
  align-items: center;
}

[class*="c-pagenation"] li {
  margin-left: 2em;
}

[class*="c-pagenation"] li:first-child {
  margin-left: 0;
}

[class*="c-pagenation"] li a {
  display: block;
  color: #999999;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  text-decoration: none;
}

[class*="c-pagenation"] li a i {
  width: 1.4em;
  height: 1.4em;
}

[class*="c-pagenation"] li a.is-active, [class*="c-pagenation"] li a:hover {
  color: #000;
}

[class*="c-pagenation"] li a.is-active {
  pointer-events: none;
}

[class*="c-pagenation"][class*="-between"] {
  width: 100%;
  justify-content: space-between;
}

[class*="c-pagenation"][class*="-between"] li.pagenation__prev a {
  line-height: 1;
}

[class*="c-pagenation"][class*="-between"] li.pagenation__prev a i {
  margin-right: 0.5em;
}

[class*="c-pagenation"][class*="-between"] li.pagenation__next a {
  line-height: 1;
}

[class*="c-pagenation"][class*="-between"] li.pagenation__next a i {
  margin-left: 0.5em;
}

/*===========================================
    c-radio
===========================================*/
[class*="c-radio"] {
  position: relative;
  display: inline-block;
  padding-left: 1.8em;
  cursor: pointer;
}

[class*="c-radio"] input {
  display: none;
}

[class*="c-radio"] input:checked + .radio__icon:before {
  transform: scale(1);
}

[class*="c-radio"] input:checked + .radio__icon:after {
  opacity: 1;
}

[class*="c-radio"] input:disabled + .radio__icon {
  border: solid 1px #ddd;
  background-color: #eaeaea;
}

[class*="c-radio"] .radio__icon {
  position: absolute;
  top: 0.2em;
  left: 0;
  display: block;
  width: 1.4em;
  height: 1.4em;
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #ddd;
  vertical-align: middle;
}

[class*="c-radio"] .radio__icon:before, [class*="c-radio"] .radio__icon:after {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  content: '';
  position: absolute;
  display: block;
}

[class*="c-radio"] .radio__icon:before {
  top: -1px;
  left: -1px;
  transform: scale(0);
  width: 100%;
  height: 100%;
  background-color: #FAFA32;
  border-radius: 50%;
  border: solid 1px #FAFA32;
}

[class*="c-radio"] .radio__icon:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  width: 0.5em;
  height: 0.5em;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0;
}

[class*="c-radio"][class*="-button"] {
  padding: 0;
}

/*===========================================
    c-table
===========================================*/
[class*="c-table"] {
  width: 100%;
  overflow-x: scroll;
}

@media screen and (max-width: 768px) {
  [class*="c-table"] {
    display: block;
  }
}

[class*="c-table"] th, [class*="c-table"] td {
  padding: 0.8em 1em;
  line-height: 1.4;
}

[class*="c-table"][class*="-box"] th, [class*="c-table"][class*="-box"] td {
  border: solid 1px #ddd;
}

[class*="c-table"][class*="-box"] th {
  background-color: #eaeaea;
}

[class*="c-table"][class*="-border"] tbody {
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
}

[class*="c-table"][class*="-border"] tbody th {
  color: #000;
  font-size: 1em;
  font-weight: bold;
}

[class*="c-table"][class*="-border"] tr {
  border-top: solid 1px #ddd;
}

[class*="c-table"][class*="-border"] tr:first-child {
  border: none;
}

[class*="c-table"][class*="-border"] th {
  color: #999999;
  font-size: 0.8em;
}

[class*="c-table"][class*="-border"] th, [class*="c-table"][class*="-border"] td {
  vertical-align: middle;
}

[class*="c-table"][class*="-hover"] tbody tr {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
}

[class*="c-table"][class*="-hover"] tbody tr:hover {
  background-color: rgba(250, 250, 50, 0.1);
}

[class*="c-table"][class*="-stripe"] thead {
  background-color: #eaeaea;
}

[class*="c-table"][class*="-stripe"] tbody tr:nth-child(2n) {
  background-color: #f1f1f1;
}

/*===========================================
    c-button
===========================================*/
[class*="c-tooltip"] {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0.2em 0.4em;
  background-color: #000;
  border: solid #000;
  border-width: 2px;
  border-radius: 0;
  color: #fff;
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;
}

[class*="c-tooltip"]:before {
  content: '';
  position: absolute;
  display: block;
  transform: translateX(-0.3em);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.6em 0.3em 0 0.3em;
  border-color: #000 transparent transparent transparent;
}

[class*="c-tooltip"][class*="-black"] {
  background-color: #000;
  color: #fff;
}

[class*="c-tooltip"][class*="-white"] {
  background-color: #fff;
  color: #000;
}

[class*="c-tooltip"][class*="-primary"] {
  background-color: #FAFA32;
  color: #fff;
}

[class*="c-tooltip"][class*="-secondary"] {
  background-color: #FF6D60;
  color: #fff;
}

[class*="c-tooltip"][class*="-xl"] {
  font-size: 1.4rem;
}

[class*="c-tooltip"][class*="-lg"] {
  font-size: 1.125rem;
}

[class*="c-tooltip"][class*="-md"] {
  font-size: 1rem;
}

[class*="c-tooltip"][class*="-sm"] {
  font-size: 0.875rem;
}

[class*="c-tooltip"][class*="-xs"] {
  font-size: 0.75rem;
}

[class*="c-tooltip"][class*="-top"]:before {
  bottom: 100%;
  left: 50%;
  transform: translateX(-0.3em);
  border-width: 0 0.3em 0.6em 0.3em;
  border-color: transparent transparent #000 transparent;
}

[class*="c-tooltip"][class*="-bottom"]:before {
  top: 100%;
  left: 50%;
  transform: translateX(-0.3em);
  border-width: 0.6em 0.3em 0 0.3em;
  border-color: #000 transparent transparent transparent;
}

[class*="c-tooltip"][class*="-left"]:before {
  top: 50%;
  right: 100%;
  transform: translateY(-0.3em);
  border-width: 0.3em 0.6em 0.3em 0;
  border-color: transparent #000 transparent transparent;
}

[class*="c-tooltip"][class*="-right"]:before {
  top: 50%;
  left: 100%;
  transform: translateY(-0.3em);
  border-width: 0.3em 0 0.3em 0.6em;
  border-color: transparent transparent transparent #000;
}

/*===========================================
    c-window
===========================================*/
[class*="c-window"] {
  position: relative;
  z-index: 1;
  display: block;
  background-color: #fff;
}

[class*="c-window"] > * {
  margin-top: 2rem;
}

[class*="c-window"] > *:first-child {
  margin-top: 0 !important;
}

[class*="c-window"] .window__caption {
  font-size: 2em;
}

[class*="c-window"] .window__close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  display: block;
  margin-top: 0 !important;
}

[class*="c-window"][class*="-black"] {
  background-color: #000;
}

[class*="c-window"][class*="-white"] {
  background-color: #fff;
}

[class*="c-window"][class*="-primary"] {
  background-color: #FAFA32;
}

[class*="c-window"][class*="-secondary"] {
  background-color: #FF6D60;
}

[class*="c-window"][class*="-xl"] {
  padding: 2.8rem 3.36rem;
  font-size: 1.4rem;
}

[class*="c-window"][class*="-xl"] > * {
  margin-top: 1.4rem;
}

[class*="c-window"][class*="-lg"] {
  padding: 2.25rem 2.7rem;
  font-size: 1.125rem;
}

[class*="c-window"][class*="-lg"] > * {
  margin-top: 1.125rem;
}

[class*="c-window"][class*="-md"] {
  padding: 2rem 2.4rem;
  font-size: 1rem;
}

[class*="c-window"][class*="-md"] > * {
  margin-top: 1rem;
}

[class*="c-window"][class*="-sm"] {
  padding: 1.75rem 2.1rem;
  font-size: 0.875rem;
}

[class*="c-window"][class*="-sm"] > * {
  margin-top: 0.875rem;
}

[class*="c-window"][class*="-xs"] {
  padding: 1.5rem 1.8rem;
  font-size: 0.75rem;
}

[class*="c-window"][class*="-xs"] > * {
  margin-top: 0.75rem;
}

[class*="c-window"][class*="-shadow"] {
  box-shadow: 1em 1em 1em rgba(0, 0, 0, 0.05);
}

/*===========================================
    p-top
===========================================*/
#p-top .p-keyVisual {
  position: relative;
  display: flex;
  align-items: center;
  width: 100vw;
}

@media screen and (max-width: 768px) {
  #p-top .p-keyVisual {
    max-height: 740px;
    height: 75vh;
  }
}

#p-top .p-keyVisual .keyVisual__inner {
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #p-top .p-keyVisual .keyVisual__inner {
    min-width: 1120px;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 160px;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-keyVisual .keyVisual__inner {
    padding: 0 36px;
  }
}

#p-top .p-keyVisual .keyVisual__inner h2 + *, #p-top .p-keyVisual .keyVisual__inner h3 + * {
  margin-top: 1.4rem;
}

#p-top .p-keyVisual .keyVisual__inner h2 {
  margin-bottom: 4rem;
}

@media screen and (min-width: 769px), print {
  #p-top .p-keyVisual .keyVisual__inner h2 {
    height: 5.5rem;
  }
}

@media screen and (min-width: 769px), print {
  #p-top .p-keyVisual .keyVisual__inner h2 img {
    width: auto;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-keyVisual .keyVisual__inner h3 {
    font-size: 1.6rem;
  }
}

#p-top .p-keyVisual .keyVisual__inner > p {
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 769px), print {
  #p-top .p-keyVisual .keyVisual__inner > p {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-keyVisual .keyVisual__inner > p {
    width: 100%;
  }
}

#p-top .p-keyVisual .keyVisual__inner > p + * {
  margin-top: 5rem;
}

@media screen and (min-width: 769px), print {
  #p-top .p-keyVisual .keyVisual__inner {
    padding: 11vh 160px 8vh;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-keyVisual .keyVisual__inner {
    padding: 10vh 0 0 36px;
  }
}

#p-top .p-keyVisual .keyVisual__image {
  position: relative;
  width: 100%;
}

#p-top .p-keyVisual .keyVisual__image > img {
  display: block;
}

@media screen and (min-width: 769px), print {
  #p-top .p-keyVisual .keyVisual__image > img.image__base {
    width: 55%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-keyVisual .keyVisual__image > img.image__base {
    width: 63%;
  }
}

#p-top .p-keyVisual .keyVisual__image .img__slide {
  position: absolute;
}

@media screen and (min-width: 769px), print {
  #p-top .p-keyVisual .keyVisual__image .img__slide {
    top: -9%;
    right: 7%;
    width: 59%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-keyVisual .keyVisual__image .img__slide {
    top: -10%;
    right: -5%;
    width: 66.5%;
  }
}

#p-top .p-keyVisual .keyVisual__image .img__slide img {
  position: absolute;
  width: 100%;
}

#p-top .p-keyVisual .keyVisual__title {
  margin-top: 3rem;
}

#p-top .p-keyVisual .keyVisual__title img {
  display: block;
}

@media screen and (min-width: 769px), print {
  #p-top .p-keyVisual .keyVisual__title img {
    width: 35%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-keyVisual .keyVisual__title img {
    width: 50%;
  }
}

#p-top .p-keyVisual .keyVisual__decoration {
  position: absolute;
}

@media screen and (min-width: 769px), print {
  #p-top .p-keyVisual .keyVisual__decoration {
    bottom: -120px;
    right: -40px;
    max-width: 480px;
    width: 28vw;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-keyVisual .keyVisual__decoration {
    bottom: -120px;
    right: -16px;
    width: 176px;
  }
}

#p-top .p-keyVisual .keyVisual__decoration img {
  display: block;
  width: 100%;
}

#p-top .p-overview {
  margin-top: 12rem;
}

#p-top .p-overview .overview__inner {
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #p-top .p-overview .overview__inner {
    min-width: 1120px;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 160px;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-overview .overview__inner {
    padding: 0 36px;
  }
}

#p-top .p-overview .overview__inner h2 + *, #p-top .p-overview .overview__inner h3 + * {
  margin-top: 1.4rem;
}

#p-top .p-overview .overview__inner h2 {
  margin-bottom: 4rem;
}

@media screen and (min-width: 769px), print {
  #p-top .p-overview .overview__inner h2 {
    height: 5.5rem;
  }
}

@media screen and (min-width: 769px), print {
  #p-top .p-overview .overview__inner h2 img {
    width: auto;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-overview .overview__inner h3 {
    font-size: 1.6rem;
  }
}

#p-top .p-overview .overview__inner > p {
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 769px), print {
  #p-top .p-overview .overview__inner > p {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-overview .overview__inner > p {
    width: 100%;
  }
}

#p-top .p-overview .overview__inner > p + * {
  margin-top: 5rem;
}

#p-top .p-overview .overview__title {
  letter-spacing: 0.3em;
}

@media screen and (min-width: 769px), print {
  #p-top .p-overview .overview__title {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-overview .overview__title {
    font-size: 2.4rem;
  }
}

#p-top .p-overview .overview__text {
  margin-top: 3rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 2.6 !important;
}

@media screen and (min-width: 769px), print {
  #p-top .p-overview .overview__text {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-overview .overview__text {
    font-size: 1.125rem;
  }
}

#p-top .p-overview .overview__link {
  margin-top: 8rem !important;
}

#p-top .p-menu {
  margin-top: 12rem;
}

#p-top .p-menu .menu__inner {
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #p-top .p-menu .menu__inner {
    min-width: 1120px;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 160px;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-menu .menu__inner {
    padding: 0 36px;
  }
}

#p-top .p-menu .menu__inner h2 + *, #p-top .p-menu .menu__inner h3 + * {
  margin-top: 1.4rem;
}

#p-top .p-menu .menu__inner h2 {
  margin-bottom: 4rem;
}

@media screen and (min-width: 769px), print {
  #p-top .p-menu .menu__inner h2 {
    height: 5.5rem;
  }
}

@media screen and (min-width: 769px), print {
  #p-top .p-menu .menu__inner h2 img {
    width: auto;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-menu .menu__inner h3 {
    font-size: 1.6rem;
  }
}

#p-top .p-menu .menu__inner > p {
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 769px), print {
  #p-top .p-menu .menu__inner > p {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-menu .menu__inner > p {
    width: 100%;
  }
}

#p-top .p-menu .menu__inner > p + * {
  margin-top: 5rem;
}

#p-top .p-menu .menu__list {
  display: flex;
  flex-wrap: wrap;
}

#p-top .p-menu .menu__list .list__item figure img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #p-top .p-menu .menu__list .list__item figure img {
    transform: scale(1.2);
  }
}

#p-top .p-menu .menu__list .list__item figure figcaption {
  display: block;
  font-family: "FandS_Gothic", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif !important;
  text-align: center;
  letter-spacing: -0.05em;
}

@media screen and (min-width: 769px), print {
  #p-top .p-menu .menu__list .list__item figure figcaption {
    margin-top: 0;
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-menu .menu__list .list__item figure figcaption {
    margin-top: 0.5em;
    font-size: 1.125rem;
  }
}

#p-top .p-menu .menu__link {
  margin-top: 8rem !important;
}

#p-top .p-member {
  margin-top: 12rem;
}

#p-top .p-member .member__inner {
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #p-top .p-member .member__inner {
    min-width: 1120px;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 160px;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-member .member__inner {
    padding: 0 36px;
  }
}

#p-top .p-member .member__inner h2 + *, #p-top .p-member .member__inner h3 + * {
  margin-top: 1.4rem;
}

#p-top .p-member .member__inner h2 {
  margin-bottom: 4rem;
}

@media screen and (min-width: 769px), print {
  #p-top .p-member .member__inner h2 {
    height: 5.5rem;
  }
}

@media screen and (min-width: 769px), print {
  #p-top .p-member .member__inner h2 img {
    width: auto;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-member .member__inner h3 {
    font-size: 1.6rem;
  }
}

#p-top .p-member .member__inner > p {
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 769px), print {
  #p-top .p-member .member__inner > p {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-member .member__inner > p {
    width: 100%;
  }
}

#p-top .p-member .member__inner > p + * {
  margin-top: 5rem;
}

@media screen and (max-width: 768px) {
  #p-top .p-member .member__inner h2 {
    font-size: 4.4rem;
  }
}

#p-top .p-member .member__image {
  width: 100%;
}

#p-top .p-member .member__image img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #p-top .p-member .member__image img {
    transform: scale(1.2);
  }
}

#p-top .p-member .member__image .image__name {
  margin-top: 0;
}

#p-top .p-member .member__image .image__name p {
  font-family: "FandS_Gothic", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif !important;
  font-size: 1.75rem;
  text-align: center;
  letter-spacing: -0.03em;
}

#p-top .p-member .member__link {
  margin-top: 8rem !important;
}

#p-top .p-howto {
  margin-top: 12rem;
}

#p-top .p-howto .howto__inner {
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #p-top .p-howto .howto__inner {
    min-width: 1120px;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 160px;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-howto .howto__inner {
    padding: 0 36px;
  }
}

#p-top .p-howto .howto__inner h2 + *, #p-top .p-howto .howto__inner h3 + * {
  margin-top: 1.4rem;
}

#p-top .p-howto .howto__inner h2 {
  margin-bottom: 4rem;
}

@media screen and (min-width: 769px), print {
  #p-top .p-howto .howto__inner h2 {
    height: 5.5rem;
  }
}

@media screen and (min-width: 769px), print {
  #p-top .p-howto .howto__inner h2 img {
    width: auto;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-howto .howto__inner h3 {
    font-size: 1.6rem;
  }
}

#p-top .p-howto .howto__inner > p {
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 769px), print {
  #p-top .p-howto .howto__inner > p {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-howto .howto__inner > p {
    width: 100%;
  }
}

#p-top .p-howto .howto__inner > p + * {
  margin-top: 5rem;
}

@media screen and (max-width: 768px) {
  #p-top .p-howto .howto__title {
    width: 70%;
  }
}

#p-top .p-howto .howto__step {
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #p-top .p-howto .howto__step {
    margin-top: 10rem !important;
  }
}

#p-top .p-howto .howto__step .step__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-top: 6rem;
}

@media screen and (min-width: 769px), print {
  #p-top .p-howto .howto__step .step__item {
    padding: 0 0 0 8.5rem;
  }
}

#p-top .p-howto .howto__step .step__item:first-child {
  margin-top: 0;
}

#p-top .p-howto .howto__step .step__number {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  margin-left: -1rem;
}

@media screen and (min-width: 769px), print {
  #p-top .p-howto .howto__step .step__number {
    width: 6rem;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-howto .howto__step .step__number {
    width: 5rem;
  }
}

@media screen and (min-width: 769px), print {
  #p-top .p-howto .howto__step .step__content {
    width: calc(100% - 60%);
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-howto .howto__step .step__content {
    width: 100%;
  }
}

#p-top .p-howto .howto__step .step__content .content__title {
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.8em;
  white-space: nowrap;
}

@media screen and (min-width: 769px), print {
  #p-top .p-howto .howto__step .step__content .content__title {
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-howto .howto__step .step__content .content__title {
    font-size: 3rem;
    padding-left: 6.2rem;
  }
}

#p-top .p-howto .howto__step .step__content .content__process {
  margin-top: 2rem;
  font-size: 1.125rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  #p-top .p-howto .howto__step .step__content .content__process {
    padding-left: 6.2rem;
  }
}

#p-top .p-howto .howto__step .step__content .content__text {
  margin-top: 2rem;
  font-weight: bold;
}

@media screen and (min-width: 769px), print {
  #p-top .p-howto .howto__step .step__content .content__image {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-howto .howto__step .step__content .content__image {
    width: 100%;
    margin-top: 2rem;
  }
}

#p-top .p-howto .howto__step .step__content .content__image img {
  display: block;
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #p-top .p-howto .howto__step .step__image {
    position: relative;
    margin-top: -6.5%;
    margin-right: -4.5%;
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-howto .howto__step .step__image {
    display: none;
  }
}

#p-top .p-howto .howto__step .step__image img {
  display: block;
  width: 100%;
}

#p-top .p-howto .howto__end {
  margin-top: 9rem;
}

#p-top .p-howto .howto__end span {
  padding-bottom: 0.8em;
  border-bottom: solid 10px #000;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 3.2;
}

#p-top .p-howto .howto__link {
  margin-top: 8rem !important;
}

#p-top .p-works {
  margin-top: 12rem;
}

#p-top .p-works .works__inner {
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #p-top .p-works .works__inner {
    min-width: 1120px;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 160px;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-works .works__inner {
    padding: 0 36px;
  }
}

#p-top .p-works .works__inner h2 + *, #p-top .p-works .works__inner h3 + * {
  margin-top: 1.4rem;
}

#p-top .p-works .works__inner h2 {
  margin-bottom: 4rem;
}

@media screen and (min-width: 769px), print {
  #p-top .p-works .works__inner h2 {
    height: 5.5rem;
  }
}

@media screen and (min-width: 769px), print {
  #p-top .p-works .works__inner h2 img {
    width: auto;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-works .works__inner h3 {
    font-size: 1.6rem;
  }
}

#p-top .p-works .works__inner > p {
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 769px), print {
  #p-top .p-works .works__inner > p {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-works .works__inner > p {
    width: 100%;
  }
}

#p-top .p-works .works__inner > p + * {
  margin-top: 5rem;
}

#p-top .p-works .works__navigation {
  font-family: "FandS_Gothic", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif !important;
  font-size: 0.75rem;
  letter-spacing: 0;
}

#p-top .p-works .works__list {
  margin: 4rem auto 0;
}

@media screen and (min-width: 769px), print {
  #p-top .p-works .works__list {
    min-width: 1120px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-works .works__list {
    width: 100%;
  }
}

#p-top .p-works .works__list .list__item {
  position: relative;
}

#p-top .p-works .works__list .list__item a {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding-top: 60%;
  color: #000;
}

#p-top .p-works .works__list .list__item a .item__thumbnail {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
  width: 100%;
  object-fit: cover;
}

#p-top .p-works .works__list .list__item a .item__information {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transform: translateY(101%);
  width: 100%;
  padding: 1em;
  background-color: rgba(250, 250, 50, 0.8);
}

@media screen and (min-width: 769px), print {
  #p-top .p-works .works__list .list__item a .item__information {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  #p-top .p-works .works__list .list__item a .item__information {
    justify-content: flex-end;
    height: 100%;
  }
}

#p-top .p-works .works__list .list__item a .item__category {
  font-family: "FandS_Gothic", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif !important;
  font-size: 0.875rem;
}

#p-top .p-works .works__list .list__item a .item__title {
  font-size: 0.75rem;
}

#p-top .p-works .works__list .list__item a:hover .item__thumbnail {
  transform: translate(-50%, -50%) scale(1.1);
}

#p-top .p-works .works__list .list__item a:hover .item__information {
  transform: translateY(0);
}

#p-top .p-works .works__link {
  margin-top: 8rem !important;
  margin-bottom: 14rem;
}

/*===========================================
    p-404
===========================================*/
/*===========================================
    p-sample
===========================================*/
#p-sample main {
  position: relative;
}

#p-sample main.is-color-original {
  background-color: #f5f5f5;
  color: #000;
}

#p-sample main.is-color-original a {
  color: #000;
}

#p-sample main.is-color-original a:before {
  border: solid 1px #000;
}

#p-sample main.is-color-original a.is-active:before {
  background-color: #000;
  border: solid 1px #000;
}

#p-sample main.is-color-white {
  background-color: #fff;
  color: #000;
}

#p-sample main.is-color-white a {
  color: #000;
}

#p-sample main.is-color-white a:before {
  border: solid 1px #000;
}

#p-sample main.is-color-white a.is-active:before {
  background-color: #000;
  border: solid 1px #000;
}

#p-sample main.is-color-black {
  background-color: #000;
  color: #fff;
}

#p-sample main.is-color-black a {
  color: #fff;
}

#p-sample main.is-color-black a:before {
  border: solid 1px #fff;
}

#p-sample main.is-color-black a.is-active:before {
  background-color: #fff;
  border: solid 1px #fff;
}

#p-sample .p-head {
  font-family: "Fira Code", monospace;
  font-size: 0.75rem;
}

#p-sample .p-head .head__index {
  position: fixed;
  top: 2rem;
  left: 2rem;
  width: 12rem;
}

#p-sample .p-head .head__index nav {
  margin-top: 2em;
}

#p-sample .p-head .head__information {
  position: fixed;
  top: 2rem;
  right: 2rem;
  display: flex;
  width: calc(100% - 12rem - 4rem);
}

#p-sample .p-head .head__information nav {
  margin-left: 4em;
}

#p-sample .p-head .head__title {
  margin-right: auto;
  font-weight: bold;
}

#p-sample .p-head .head__navgation:first-child {
  margin-top: 0;
}

#p-sample .p-head .head__navgation .navgation__group {
  font-weight: bold;
}

#p-sample .p-head .head__navgation .navgation__link li {
  vertical-align: bottom;
}

#p-sample .p-head .head__navgation .navgation__link li a:before {
  content: '';
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  margin-right: 0.4em;
}

#p-sample .p-head .head__navgation .navgation__item dl {
  display: flex;
}

#p-sample .p-head .head__navgation .navgation__item dl dt {
  width: 4.5em;
}

#p-sample .p-flame {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}

#p-sample .p-flame iframe {
  width: 100%;
  height: 100%;
}

#p-sample .p-parts {
  width: 100%;
  min-height: 100vh;
  padding: 14rem 2rem 8rem 14rem;
}

#p-sample .p-parts .parts__section {
  display: flex;
  margin-top: 7rem;
  padding-top: 7rem;
  border-top: solid 1px #000;
}

#p-sample .p-parts .parts__section:first-child {
  margin-top: 0;
}

#p-sample .p-parts .parts__section .section__title {
  width: 10rem;
  margin-right: 2rem;
  font-family: "Fira Code", monospace;
  font-size: 0.75rem;
  font-weight: bold;
}

#p-sample .p-parts .parts__section .section__content {
  width: calc(100% - 12rem);
}

#p-sample .p-parts .parts__section .section__block {
  margin-top: 3rem;
}

#p-sample .p-parts .parts__section .section__block:first-child {
  margin-top: 0;
}

#p-sample .p-parts .parts__section .section__block h3 {
  font-size: 0.75rem;
}

#p-sample .p-parts .parts__section .section__block h3 + * {
  margin-top: 1rem;
}

#p-sample .p-parts .parts__section .section__block [class*='block__box'] {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  min-height: 48px;
  background-color: #ddd;
  color: #fff;
  font-family: "Fira Code", monospace;
  font-size: 0.75rem;
}

#p-sample .p-parts .parts__section .section__block [class*='block__box']:nth-child(2n) {
  background-color: #e2e2e2;
}

#p-sample .p-parts .parts__section .section__block [class*='block__box'][class*='-h100'] {
  min-height: 100px;
}

#p-sample .p-parts .parts__section .section__block [class*='block__box'][class*='-circle'] {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/*===========================================
    u-display
===========================================*/
.u-display--block {
  display: block !important;
}

.u-display--inline {
  display: inline !important;
}

.u-display--none {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .u-display--none-sm {
    display: none !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  .u-display--none-md {
    display: none !important;
  }
}

@media screen and (min-width: 769px), print {
  .u-display--none-lg {
    display: none !important;
  }
}

/*===========================================
    u-grid
===========================================*/
[class*="u-grid"] {
  display: flex !important;
  flex-wrap: wrap !important;
  width: 100% !important;
}

[class*="u-grid"] > *:first-child {
  margin-left: 0 !important;
}

@media screen and (max-width: 768px) {
  [class*="u-grid"] > *:first-child {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l1"] > * {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l1"] > *:nth-child(-n + 1) {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l2"] > * {
    width: calc(100% / 2) !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l2"] > *:nth-child(2n-1) {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l2"] > *:nth-child(-n + 2) {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l3"] > * {
    width: calc(100% / 3) !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l3"] > *:nth-child(3n-2) {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l3"] > *:nth-child(-n + 3) {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l4"] > * {
    width: calc(100% / 4) !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l4"] > *:nth-child(4n-3) {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l4"] > *:nth-child(-n + 4) {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l5"] > * {
    width: calc(100% / 5) !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l5"] > *:nth-child(5n-4) {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-l5"] > *:nth-child(-n + 5) {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m1"] > * {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m1"] > *:nth-child(-n + 1) {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m2"] > * {
    width: calc(100% / 2) !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m2"] > *:nth-child(2n-1) {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m2"] > *:nth-child(-n + 2) {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m3"] > * {
    width: calc(100% / 3) !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m3"] > *:nth-child(3n-2) {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m3"] > *:nth-child(-n + 3) {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m4"] > * {
    width: calc(100% / 4) !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m4"] > *:nth-child(4n-3) {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m4"] > *:nth-child(-n + 4) {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m5"] > * {
    width: calc(100% / 5) !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m5"] > *:nth-child(5n-4) {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-m5"] > *:nth-child(-n + 5) {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s1"] > * {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s1"] > *:nth-child(-n + 1) {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s2"] > * {
    width: calc(100% / 2) !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s2"] > *:nth-child(2n-1) {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s2"] > *:nth-child(-n + 2) {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s3"] > * {
    width: calc(100% / 3) !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s3"] > *:nth-child(3n-2) {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s3"] > *:nth-child(-n + 3) {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s4"] > * {
    width: calc(100% / 4) !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s4"] > *:nth-child(4n-3) {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s4"] > *:nth-child(-n + 4) {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s5"] > * {
    width: calc(100% / 5) !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s5"] > *:nth-child(5n-4) {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-s5"] > *:nth-child(-n + 5) {
    margin-top: 0 !important;
  }
}

[class*="u-grid"][class*="-lg"] > * {
  margin-top: 2.5rem;
  margin-left: 2.5rem;
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-lg"][class*="-l5"] > * {
    width: calc((100% - 2.5rem * 4) / 5) !important;
  }
  [class*="u-grid"][class*="-lg"][class*="-l4"] > * {
    width: calc((100% - 2.5rem * 3) / 4) !important;
  }
  [class*="u-grid"][class*="-lg"][class*="-l3"] > * {
    width: calc((100% - 2.5rem * 2) / 3) !important;
  }
  [class*="u-grid"][class*="-lg"][class*="-l2"] > * {
    width: calc((100% - 2.5rem * 1) / 2) !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-lg"][class*="-m5"] > * {
    width: calc((100% - 2.5rem * 4) / 5) !important;
  }
  [class*="u-grid"][class*="-lg"][class*="-m4"] > * {
    width: calc((100% - 2.5rem * 3) / 4) !important;
  }
  [class*="u-grid"][class*="-lg"][class*="-m3"] > * {
    width: calc((100% - 2.5rem * 2) / 3) !important;
  }
  [class*="u-grid"][class*="-lg"][class*="-m2"] > * {
    width: calc((100% - 2.5rem * 1) / 2) !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-lg"][class*="-s5"] > * {
    width: calc((100% - 2.5rem * 4) / 5) !important;
  }
  [class*="u-grid"][class*="-lg"][class*="-s4"] > * {
    width: calc((100% - 2.5rem * 3) / 4) !important;
  }
  [class*="u-grid"][class*="-lg"][class*="-s3"] > * {
    width: calc((100% - 2.5rem * 2) / 3) !important;
  }
  [class*="u-grid"][class*="-lg"][class*="-s2"] > * {
    width: calc((100% - 2.5rem * 1) / 2) !important;
  }
}

[class*="u-grid"][class*="-lg"][class*="-jfs"] > *,
[class*="u-grid"][class*="-lg"][class*="-jfb"] > *,
[class*="u-grid"][class*="-lg"][class*="-jct"] > * {
  width: auto !important;
  margin-top: 0 !important;
}

[class*="u-grid"][class*="-md"] > * {
  margin-top: 1.5rem;
  margin-left: 1.5rem;
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-md"][class*="-l5"] > * {
    width: calc((100% - 1.5rem * 4) / 5) !important;
  }
  [class*="u-grid"][class*="-md"][class*="-l4"] > * {
    width: calc((100% - 1.5rem * 3) / 4) !important;
  }
  [class*="u-grid"][class*="-md"][class*="-l3"] > * {
    width: calc((100% - 1.5rem * 2) / 3) !important;
  }
  [class*="u-grid"][class*="-md"][class*="-l2"] > * {
    width: calc((100% - 1.5rem * 1) / 2) !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-md"][class*="-m5"] > * {
    width: calc((100% - 1.5rem * 4) / 5) !important;
  }
  [class*="u-grid"][class*="-md"][class*="-m4"] > * {
    width: calc((100% - 1.5rem * 3) / 4) !important;
  }
  [class*="u-grid"][class*="-md"][class*="-m3"] > * {
    width: calc((100% - 1.5rem * 2) / 3) !important;
  }
  [class*="u-grid"][class*="-md"][class*="-m2"] > * {
    width: calc((100% - 1.5rem * 1) / 2) !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-md"][class*="-s5"] > * {
    width: calc((100% - 1.5rem * 4) / 5) !important;
  }
  [class*="u-grid"][class*="-md"][class*="-s4"] > * {
    width: calc((100% - 1.5rem * 3) / 4) !important;
  }
  [class*="u-grid"][class*="-md"][class*="-s3"] > * {
    width: calc((100% - 1.5rem * 2) / 3) !important;
  }
  [class*="u-grid"][class*="-md"][class*="-s2"] > * {
    width: calc((100% - 1.5rem * 1) / 2) !important;
  }
}

[class*="u-grid"][class*="-md"][class*="-jfs"] > *,
[class*="u-grid"][class*="-md"][class*="-jfb"] > *,
[class*="u-grid"][class*="-md"][class*="-jct"] > * {
  width: auto !important;
  margin-top: 0 !important;
}

[class*="u-grid"][class*="-sm"] > * {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

@media screen and (min-width: 769px), print {
  [class*="u-grid"][class*="-sm"][class*="-l5"] > * {
    width: calc((100% - 0.5rem * 4) / 5) !important;
  }
  [class*="u-grid"][class*="-sm"][class*="-l4"] > * {
    width: calc((100% - 0.5rem * 3) / 4) !important;
  }
  [class*="u-grid"][class*="-sm"][class*="-l3"] > * {
    width: calc((100% - 0.5rem * 2) / 3) !important;
  }
  [class*="u-grid"][class*="-sm"][class*="-l2"] > * {
    width: calc((100% - 0.5rem * 1) / 2) !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  [class*="u-grid"][class*="-sm"][class*="-m5"] > * {
    width: calc((100% - 0.5rem * 4) / 5) !important;
  }
  [class*="u-grid"][class*="-sm"][class*="-m4"] > * {
    width: calc((100% - 0.5rem * 3) / 4) !important;
  }
  [class*="u-grid"][class*="-sm"][class*="-m3"] > * {
    width: calc((100% - 0.5rem * 2) / 3) !important;
  }
  [class*="u-grid"][class*="-sm"][class*="-m2"] > * {
    width: calc((100% - 0.5rem * 1) / 2) !important;
  }
}

@media screen and (max-width: 768px) {
  [class*="u-grid"][class*="-sm"][class*="-s5"] > * {
    width: calc((100% - 0.5rem * 4) / 5) !important;
  }
  [class*="u-grid"][class*="-sm"][class*="-s4"] > * {
    width: calc((100% - 0.5rem * 3) / 4) !important;
  }
  [class*="u-grid"][class*="-sm"][class*="-s3"] > * {
    width: calc((100% - 0.5rem * 2) / 3) !important;
  }
  [class*="u-grid"][class*="-sm"][class*="-s2"] > * {
    width: calc((100% - 0.5rem * 1) / 2) !important;
  }
}

[class*="u-grid"][class*="-sm"][class*="-jfs"] > *,
[class*="u-grid"][class*="-sm"][class*="-jfb"] > *,
[class*="u-grid"][class*="-sm"][class*="-jct"] > * {
  width: auto !important;
  margin-top: 0 !important;
}

[class*="u-grid"][class*="-jfs"] {
  justify-content: flex-start !important;
}

[class*="u-grid"][class*="-jfs"] > * {
  width: auto !important;
}

[class*="u-grid"][class*="-jfb"] {
  justify-content: flex-end !important;
}

[class*="u-grid"][class*="-jfb"] > * {
  width: auto !important;
}

[class*="u-grid"][class*="-jct"] {
  justify-content: center !important;
}

[class*="u-grid"][class*="-jct"] > * {
  width: auto !important;
}

[class*="u-grid"][class*="-jsb"] {
  justify-content: space-between !important;
}

[class*="u-grid"][class*="-jsb"] > * {
  width: auto !important;
}

[class*="u-grid"][class*="-jsa"] {
  justify-content: space-around !important;
}

[class*="u-grid"][class*="-jsa"] > * {
  width: auto !important;
}

[class*="u-grid"][class*="-jse"] {
  justify-content: space-evenly !important;
}

[class*="u-grid"][class*="-jse"] > * {
  width: auto !important;
}

[class*="u-grid"][class*="-afs"] {
  align-items: flex-start !important;
}

[class*="u-grid"][class*="-afs"] > * {
  width: auto !important;
}

[class*="u-grid"][class*="-afe"] {
  align-items: flex-end !important;
}

[class*="u-grid"][class*="-afe"] > * {
  width: auto !important;
}

[class*="u-grid"][class*="-act"] {
  align-items: center !important;
}

[class*="u-grid"][class*="-act"] > * {
  width: auto !important;
}

[class*="u-grid"][class*="-abl"] {
  align-items: baseline !important;
}

[class*="u-grid"][class*="-abl"] > * {
  width: auto !important;
}

[class*="u-grid"][class*="-asr"] {
  align-items: stretch !important;
}

[class*="u-grid"][class*="-asr"] > * {
  width: auto !important;
}

[class*="u-grid"][class*="-nowrap"] {
  flex-wrap: nowrap !important;
  overflow-x: scroll !important;
  padding-bottom: 1rem !important;
}

[class*="u-grid"][class*="-nowrap"] > * {
  width: auto !important;
  margin-top: 0 !important;
}

[class*="u-grid"][class*="-nowrap"] > *:first-child {
  margin-left: 0 !important;
}

[class*="u-grid"][class*="-drow"] {
  flex-direction: row !important;
}

[class*="u-grid"][class*="-dcol"] {
  flex-direction: column !important;
}

[class*="u-grid"][class*="-drrow"] {
  flex-direction: row-reverse !important;
}

[class*="u-grid"][class*="-drcol"] {
  flex-direction: column-reverse !important;
}

/*===========================================
    c-text
===========================================*/
[class*="u-text"][class*="-xl"] {
  font-size: 1.4rem !important;
}

[class*="u-text"][class*="-lg"] {
  font-size: 1.125rem !important;
}

[class*="u-text"][class*="-md"] {
  font-size: 1rem !important;
}

[class*="u-text"][class*="-sm"] {
  font-size: 0.875rem !important;
}

[class*="u-text"][class*="-xs"] {
  font-size: 0.75rem !important;
}

[class*="u-text"][class*="-bold"] {
  font-weight: bold !important;
}

[class*="u-text"][class*="-italic"] {
  font-style: italic !important;
}

[class*="u-text"][class*="-underline"] {
  text-decoration: underline !important;
}

[class*="u-text"][class*="-highlight"] {
  background-color: #fafa32 !important;
}

[class*="u-text"][class*="-align-l"] {
  text-align: left !important;
}

[class*="u-text"][class*="-align-c"] {
  text-align: center !important;
}

[class*="u-text"][class*="-align-r"] {
  text-align: right !important;
}

/*===========================================
    js-loading
===========================================*/
.js-loading {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  opacity: 1;
}

.js-loading.is-active {
  opacity: 0;
  pointer-events: none;
}

.js-loading .loading__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

/*===========================================
    js-filter
===========================================*/
.js-filter__content {
  transition-property: all;
  transition-duration: 0.4s;
  transition-delay: 0;
  transition-timing-function: ease;
  display: none;
  transform: scale(0);
  opacity: 0;
}

.js-filter__content.is-active {
  animation-duration: 0.8s;
  animation-delay: 0;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: nomal;
  animation-fill-mode: both;
  animation-play-state:   running;
  animation-name: fade-in;
  display: block;
  transform: scale(1);
  opacity: 1;
}

/*===========================================
    js-scrollSwitch
===========================================*/
.js-scrollSwitch__imageWrap {
  position: relative;
}

.js-scrollSwitch__image.is-hide {
  opacity: 0;
}

.js-scrollSwitch__image.is-clone, .js-scrollSwitch__image.is-loop, .js-scrollSwitch__image.is-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
