//===========================================
//  color
//===========================================

//-------------------------------------------
//  カスタム
//-------------------------------------------
$color-white:      #fff;
$color-black:      #000;
$color-primary:    #FAFA32;
$color-secondary:  #FF6D60;


//-------------------------------------------
//  バックグランド
//-------------------------------------------
$color-background: #f1f1f1;


//-------------------------------------------
//  テキスト
//-------------------------------------------
$color-text:       #000;


//-------------------------------------------
//  リンク
//-------------------------------------------
$color-link:       $color-black;
$color-visit:      $color-black;


//-------------------------------------------
//  ドラッグ
//-------------------------------------------
$color-mark:       rgba($color-primary, 1);


//-------------------------------------------
//  ボーダー
//-------------------------------------------
$color-border:     #ddd;


//-------------------------------------------
//  シャドウ
//-------------------------------------------
$color-shadow:     rgba($color-black, 0.05);


//-------------------------------------------
//  オーバーレイ
//-------------------------------------------
$color-overlay:     rgba($color-black, 0.4);


//-------------------------------------------
//  コンテキスト
//-------------------------------------------
$color-success:    #06CDB5;
$color-warning:    #E68857;
$color-danger:     #F34237;
