/*===========================================
    c-heading
===========================================*/

$heding-size-mag: 2;

//-------------------------------------------

[class*="c-heading"] {
  position: relative;
  color: $color-text;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.2em;
  font-feature-settings : "palt";

  //-----------------------------------------
  //  size
  //-----------------------------------------
  @mixin heading-size($fontSize: $font-size-md) {
    font-size: $fontSize * $heding-size-mag;
  }

  &[class*="-xl"] {
    @include heading-size($font-size-xl);
  }

  &[class*="-lg"] {
    @include heading-size($font-size-lg);
  }

  &[class*="-md"] {
    @include heading-size($font-size-md);
  }

  &[class*="-sm"] {
    @include heading-size($font-size-sm);
  }

  &[class*="-xs"] {
    @include heading-size($font-size-xs);
  }

  &[class*="-section"] {
    font-family: $font-family-fs;
    text-indent: -0.1em;
    white-space: nowrap;
    @include media-query(lg) {
      font-size: 6.5rem;
      letter-spacing: 0.1em;
    }
    @include media-query(sm) {
      font-size: 6.5rem;
      letter-spacing: 0.04em;
      line-height: 1.4;
    }
  }


  //-----------------------------------------
  //  color
  //-----------------------------------------
  &[class*="-black"] {
    color: $color-black;
  }

  &[class*="-white"] {
    color: $color-white;
  }

  &[class*="-primary"] {
    color: $color-primary;
  }

  &[class*="-secondary"] {
    color: $color-secondary;
  }


  //-----------------------------------------
  //  align
  //-----------------------------------------
  &[class*="-align-l"] {
    text-align: left;
  }

  &[class*="-align-c"] {
    text-align: center;
  }

  &[class*="-align-r"] {
    text-align: right;
  }


  //-----------------------------------------
  //  additional
  //-----------------------------------------
  [class*="heading__sub"] {
    color: $color-text;
    font-size: 0.5em;
    font-family: $font-family-secondary;
    vertical-align: middle;

    &[class*="-top"] {
      display: block;
      margin-bottom: 0.5em;
    }

    &[class*="-bottom"] {
      display: block;
      margin-top: 0.5em;
    }

    &[class*="-before"] {
      display: inline-block;
      margin-right: 1em;
    }

    &[class*="-after"] {
      display: inline-block;
      margin-left: 1em;
    }
  }

  [class*="heading__icon"] {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;

    img {
      display: block;
      width: auto;
      height: 100%;
    }

    &[class*="--before"] {
      margin-right: 0.5em;
    }

    &[class*="--after"] {
      margin-left: 0.5em;
    }
  }
}
