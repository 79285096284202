//===========================================
//  font
//===========================================

@font-face {
  font-family: 'FandS_Gothic';
  src:  url('../fonts/FandS_Gothic.eot?lt0hbo');
  src:  url('../fonts/FandS_Gothic.eot?lt0hbo#iefix') format('embedded-opentype'),
    url('../fonts/FandS_Gothic.ttf?lt0hbo') format('truetype'),
    url('../fonts/FandS_Gothic.woff?lt0hbo') format('woff'),
    url('../fonts/FandS_Gothic.svg?lt0hbo#FandS_Gothic') format('svg');
  font-weight: normal;
  font-style: normal;
}

//-------------------------------------------
//  フォントファミリー
//-------------------------------------------
$font-family-primary:   '游ゴシック', YuGothic, 'Yu Gothic Medium', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
$font-family-secondary: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
$font-family-number:    'Roboto', sans-serif;
$font-family-fs:        'FandS_Gothic', '游ゴシック', YuGothic, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif !important;


//-------------------------------------------
//  フォントサイズ
//-------------------------------------------
$font-default-lg:       16px;
$font-default-md:       16px;
$font-default-sm:       14px;

$font-size-xl:          1.4rem;
$font-size-lg:          1.125rem;
$font-size-md:          1rem;
$font-size-sm:          0.875rem;
$font-size-xs:          0.75rem;
