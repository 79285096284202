/*===========================================
    animate
===========================================*/
.is-animated {
  animation-duration: 0.5s;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;

  &.fx-infinite {
    animation-iteration-count: infinite;
  }
}

@import "_fade";
@import "_link-fs";
